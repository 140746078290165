import React, { useEffect, useContext } from 'react';
import * as Sentry from "@sentry/react";
import Link from 'next/link';

// Prusuki components
import { Wrapper } from '../layout';
import { GlobalContext } from '../GlobalProvider/';
import messages from './messages'
import { personalErrors, texts } from './customizedErrors';
import IntlText from '../IntlText';

// Error that can't be fixed or don't affect the app at all
const ignoredErors = [
  'can\'t redefine non-configurable property "userAgent"',
  'ResizeObserver loop limit exceeded',
];

function CustomError({ clearError, error }) {
  const { currentUser } = useContext(GlobalContext);

  // useEffect(() => {
  //   const unlisten = history.listen(() => {
  //     if (error) {
  //       clearError();
  //     }
  //   });
  //   return () => {
  //     unlisten();
  //   };
  // }, []);

  const userId = currentUser?.userid;
  return (
    <Wrapper style={{ textAlign: 'center' }}>
      <h1>
        {userId && personalErrors[userId] ? (
          texts[Math.floor(Math.random() * texts.length)]
        ) : (
          <IntlText index={messages.main} />
        )}
      </h1>
      <img
        src={
          userId && personalErrors[userId]
            ? personalErrors[userId].img
            : '/img/under_construction.gif'
        }
        title="Jo is working on a fix"
        alt="Jo is working on a fix"
      />
      <br />
      <br />
      <Link href="/">
        <a>
          &larr; <IntlText index={messages.backToHomepage} />
        </a>
      </Link>
    </Wrapper>
  );
}

const CustomErrorBoundary = CustomError;


export default function ErrorBoundary({ children, ...props }) {
  return (
    <Sentry.ErrorBoundary
      fallback={<CustomErrorBoundary {...props} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
