/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

export const scope = 'app.components.Search';

const messages = {
	main: `${scope}.main`,
	nothingFound: `${scope}.nothingFound`,
	glossary: `${scope}.glossary`,
	showAllResults: `${scope}.showAllResults`,
};

export default messages;
