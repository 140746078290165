/**
 * @todo important
 */
import styled from 'styled-components';

export default styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 2%;
  white-space: nowrap;
  a {
    transition: color 500ms;
    color: #333;
    font-weight: bold;
  }
  a:hover {
    color: #fa6831;
  }
`;
