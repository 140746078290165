import styled from 'styled-components';

export default styled.input`
  border-radius: 28px;
  height: 56px;
  line-height: 56px;
  width: 500px;
  max-width: 100%;
  padding: 0 2em 0 3rem;
  border: 3px solid ${props => props.borderColor || props.theme.colors.main};
  outline: none;
  transition: border-color 300ms;
  z-index: 10;
  tab-index: 1;
  &:focus {
    border-color: ${props => props.theme.colors.mainLight};
  }
`;

export const Icon = styled.span`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 16px;
  top: 16px;
  z-index: 20;
  color: #333;
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 5;
`;
