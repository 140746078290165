/*
 * SEOHeaders Messages
 *
 * This contains all the text for the GuidePage container.
 */

export const scope = 'app.components.SEOHeaders';

const messages = {
	description: `${scope}.description`,
};

export default messages;