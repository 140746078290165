import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { adjustSrc } from '../../utils/helpers'

export const Img = styled.img<{ adaptive?: boolean }>`
  width: ${props => (props.adaptive ? 'auto' : '100%')};
  height: auto;
  clear: both;
  display: block;
  max-height: 100%;
`;

export const Wrapper = styled.div<{
  adaptive?: boolean;
  border?: boolean;
  noMargin?: boolean;
  width?: number | string;
  inList?: boolean;
}>`
  ${props => (props.adaptive ? 'display: flex; justify-content: center;' : '')}
  max-width: 100%;
  max-height: 100%;
  ${props => (props.border ? 'border: 1px solid #777;' : '')}
  width: ${props => `${props.width ? props.width + 'px' : 'auto'}`};

  @media screen and (max-width: 32em) {
    text-align: center;
    max-width: ${props => (props.inList ? '80%' : 'none')} !important;
    min-width: 0 !important;
    ${({ noMargin }) => (noMargin ? '' : 'margin: 0 auto !important;')}

    ${Img} {
      width: 100%;
      ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 1em;')}
    }
  }
`;

export interface Image {
  original: string;
  srcset?: string | boolean;
  medium?: string;
  medium_large?: string;
  thumbnail?: string;
  large?: string;
  id?: number;
  size?: string;
  meta?: object;
  resolution?: {
    [key: string]: {
      width: number;
      height: number;
    };
  };
}

export interface ImageProps {
  image: Image;
  alt?: string;
  placeholder?: string;
  size?: 'original' | string;
  adaptive?: boolean;
  width?: number | string;
  height?: number | string;
  meta?: object;
  styleObject?: object;
}

export const Image: React.FC<ImageProps> = ({
  image,
  alt,
  placeholder = '/img/placeholder.jpg',
  size = 'original',
  adaptive = false,
  width = '100%',
  height = 'auto',
  meta = {},
  styleObject = {},
  ...props
}) => {
  const imageRef = React.useRef(null);

  const src = image?.[size as string] || image?.original || placeholder;
  const srcSet = image?.srcset ? `${image.srcset}` : undefined;

  const resolution = image?.resolution?.[size];

  React.useEffect(() => {
    if (imageRef.current && resolution && 'height' in styleObject === false && (height === 'auto' || !height) ) {
      const img = imageRef.current;

      const width = img.clientWidth;
      const ratioForHeightCalculation = resolution.width / width;
      const calculatedHeight = resolution.height / ratioForHeightCalculation;
    
      if(typeof calculatedHeight === 'number' && calculatedHeight > 50) {
        img.style.height = `${calculatedHeight}px`;
        img.height = calculatedHeight;
        img.width = width;
        img.style.width = `${width}px`;
      } else {
        img.style.height = height;
        img.height = height;
      }

    }
  }, [imageRef, resolution]);

  return (
    <Wrapper width={width} {...props} adaptive={adaptive}>
      {image ? (
        <Img
          ref={imageRef}
          src={adjustSrc(src)}
          srcSet={adjustSrc(srcSet)}
          alt={alt}
          sizes="(min-width: 1024px) 820px, 100vw"
          adaptive={adaptive}
          width={width}
          height={height}
          loading="lazy"
          style={styleObject}
        />
      ) : (
        <Img src={placeholder} alt={alt} sizes="100vw" />
      )}
    </Wrapper>
  );
};

Image.propTypes = {
  image: PropTypes.exact({
    original: PropTypes.string.isRequired,
    srcset: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    thumbnail: PropTypes.string,
    id: PropTypes.number,
    large: PropTypes.string,
    medium: PropTypes.string,
    medium_large: PropTypes.string,
    '1536x1536': PropTypes.string,
    meta: PropTypes.any,
  }),
  placeholder: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.string,
  adaptive: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  meta: PropTypes.object,
};

export default Img;
