import PropTypes from 'prop-types';
import styled from 'styled-components';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import parseHtml from 'html-react-parser';
import Link from 'next/link';
import sanitizeHtml from 'sanitize-html';

import { urlBuilder, parseSanitizedHTML } from '../../utils/helpers';
import { MenuItem } from './Menu';

const MenuItemLi = MenuItem.withComponent('div');

const Cat = styled.span`
  color: #999;
`;

function Suggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  inputValue,
  column,
  inAdmin = false,
  language,
  useLink = true,
}) {
  const isHighlighted = highlightedIndex === index;
  const matches = match(suggestion[column], inputValue);
  const parts = parse(parseSanitizedHTML(suggestion[column]), matches);

  if (useLink) {
    return (
      <div key={suggestion.slug}>
        <Link
          // selected={isHighlighted}
          href={urlBuilder({
            language: inAdmin ? `${language}/admin` : language,
            item: suggestion,
          })}
        >
          <a>
            <MenuItem
              onClick={event => {
                event.ctrlKey || event.metaKey || itemProps.onClick(event);
              }}
            >
              <div>
                {suggestion.categories_chain && (
                  <Cat>
                    {parseHtml(suggestion.categories_chain[0][0].title || '')} /{' '}
                  </Cat>
                )}
                {parts.map((part, i) =>
                  part.highlight ? (
                    <span key={String(i)} style={{ fontWeight: 700 }}>
                      {parseHtml(part.text)}
                    </span>
                  ) : (
                    <strong key={String(i)} style={{ fontWeight: 300 }}>
                      {parseHtml(part.text)}
                    </strong>
                  ),
                )}
              </div>
            </MenuItem>
          </a>
        </Link>
      </div>
    );
  }
  return (
    <div key={suggestion.slug}>
      <MenuItemLi
        onClick={event => {
          event.ctrlKey || event.metaKey || itemProps.onClick(event);
        }}
        key={suggestion.slug}
        selected={isHighlighted}
      >
        {suggestion.categories_chain && (
          <Cat>
            {parseHtml(suggestion.categories_chain[0][0].title || '')} /{' '}
          </Cat>
        )}
        {parts.map((part, i) =>
          part.highlight ? (
            <span key={String(i)} style={{ fontWeight: 700 }}>
              {parseHtml(part.text)}
            </span>
          ) : (
            <strong key={String(i)} style={{ fontWeight: 300 }}>
              {parseHtml(part.text)}
            </strong>
          ),
        )}
      </MenuItemLi>
    </div>
  );
}

Suggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.object,
  inputValue: PropTypes.string,
  column: PropTypes.string,
  suggestion: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
  inAdmin: PropTypes.bool,
};

export default Suggestion;
