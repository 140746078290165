import styled from 'styled-components';
import { themeColor } from '../../utils/themeHelpers';

export default styled.section`
  background-color: ${themeColor('main')};
  min-height: ${props => props.height || 300}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  ${props => props.center && 'text-align: center;'}
`;
