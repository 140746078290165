import React from 'react';
import styled from 'styled-components';

const Content = styled.span`
  visibility: hidden;
  background-color: #666;
  color: #fff;
  text-align: center;
  padding: 0.5em;
  border-radius: 6px;
  font-size: 0.9em;
  line-height: 1;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  width: 220px;
  top: 100%;
  left: 50%;
  margin-left: -110px;

  &:after {
    content: " ";
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #666 transparent;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted #666; /* If you want dots under the hoverable text */

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover ${Content} {
    visibility: visible;
  }
`;

const SimpleTooltip: React.FC<{
  component: any;
  content: any;
  position: String;
}> = ({ component, content, position }) => (
  <Wrapper>
    {component}
    <Content>{content}</Content>
  </Wrapper>
);

export default SimpleTooltip;
