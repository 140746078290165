/**
 * @module logger
 * @description output to stdout and stderr are consumed by Loki
 * @todo use Sentry on client side
 */

type LogTypeKeys = keyof typeof LOG_TYPE;

interface LogProps {
  condition?: null | boolean;
  message: string;
  level?: typeof LOG_TYPE[LogTypeKeys];
  stringify?: object;
  [key: string]: object | string | number | boolean | undefined;
}

const COLORS = {
  red: '\x1b[31m',
  yellow: '\x1b[33m',
  green: '\x1b[32m',
  blue: '\x1b[34m',
  cyan: '\x1b[36m',
  magenta: '\x1b[35m',
  reset: '\x1b[0m',
};

export const LOG_TYPE = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
  cache: 'cache',
  debug: 'debug',
} as const;

const COLORS_BY_TYPE = {
  info: COLORS.blue,
  warning: COLORS.yellow,
  error: COLORS.red,
  success: COLORS.green,
  cache: COLORS.magenta,
  debug: COLORS.cyan,
};

/**
 * @function log
 * async/await is used to ensure that the logs are written in NextJS middleware
 */
export const log = async ({
  condition = null,
  message,
  level = LOG_TYPE.info,
  stringify,
  ...rest
}: LogProps) => {
  if (condition !== null && !condition) return;

  if (level === 'debug' && process.env.NEXT_PUBLIC_DEBUG_MODE !== 'true')
    return;

  let stringifiedToJson = '';

  const color = COLORS_BY_TYPE[level] || COLORS.reset;

  const timestamp = new Date().toISOString();

  const logMessage = `${color}[${level}]${COLORS.reset}: ${message}`;

  if (stringify) {
    const jsonString = JSON.stringify(stringify);
    stringifiedToJson = `[JSON=${jsonString}]`;
  }

  if (level === 'error') {
    await console.error(`[${timestamp}]`, logMessage, stringifiedToJson, rest);
  } else {
    await console.log(`[${timestamp}]`, logMessage, stringifiedToJson, rest);
  }
};

log.error = (props: LogProps) => log({ ...props, level: LOG_TYPE.error });

log.info = (props: LogProps) => log({ ...props, level: LOG_TYPE.info });

log.warning = (props: LogProps) => log({ ...props, level: LOG_TYPE.warning });