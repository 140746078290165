export const debugFetchResponse = (response) => {
  return {
    //body: response?.body,
    status: response?.status,
    statusText: response?.statusText,
    headers: response?.headers,
    redirected: response?.redirected,
    ok: response?.ok,
    url: response?.url,
    type: response?.type,
    // text: response?.text?.(),
    // json: response?.json?.(),
    // blob: response?.blob?.text?.(),
    // formData: response?.formData?.(),
    // arrayBuffer: response?.arrayBuffer,
  }
}