export const texts = [
  'Why are you like this?',
  'You broke it!',
  'You broke it again!',
  'Stop hurting me! :(',
  'Please, stop!',
  'Nuclear codes successfully activated.',
  'Hard drive wipe initiated.',
];

export const personalErrors = {
  7265: {
    img: '/img/william.png',
  },
  15: {
    img: '/img/vojta.png',
  },
  7: {
    img: '/img/jakub.png',
  },
  8: {
    img: '/img/jakub.png',
  },
  2: {
    img: '/img/dan.png',
  },
};
