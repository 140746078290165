/**
 * @file App header with menu and other items.
 * @todo useVideoAutoplay ###
 */
import { useState, useRef, useContext } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';
import MainNavigation from '@prusa3d-platform/prusa-main-navigation';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import UserMenu from '../UserMenu';
import MobileWidget from '../layout/MobileWidget';
import LanguageSwitcher from '../LanguageSwitcher';
import { GlobalContext } from '../GlobalProvider/';
import { useCheckRole, useOutsideClick } from '../../utils/hooks';
import { ROLES } from '../../utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { MAX_WIDTH } from '../../utils/constants';

import Burger from './Burger';
import Menu from './Menu';
import Nav from './Nav';
import MobileNav from './MobileNav';
import MobileA from './MobileA';
import NavItem from './NavItem';
import Header from './Header';
import RightWidget from './RightWidget';

const WidgetWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: ${MAX_WIDTH};
`;

function AppHeader({ nav, translations }) {
  const { roles } = useContext(GlobalContext);
  const [menuOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const { isInAnyRole, isInRole } = useCheckRole(roles);
  const { locale } = useRouter();

  const canEdit = isInAnyRole([ROLES.ADMIN, ROLES.AUTHOR, ROLES.COMMENT_ADMIN]);
  const canViewPrivate = isInRole(ROLES.EMPLOYEE) || canEdit;
  const isAdmin = isInRole(ROLES.ADMIN);

  useOutsideClick(ref, () => {
    if (menuOpen) setOpen(false);
  });

  return (
    <header>
      <Header isTest={process.env.TESTING_BUILD}>
        <WidgetWrapper>
          <RightWidget>
            <LanguageSwitcher translations={translations} />
            <UserMenu
              canEdit={canEdit}
              canViewPrivate={canViewPrivate}
              isAdmin={isAdmin}
            />
          </RightWidget>
        </WidgetWrapper>
      </Header>
      <MainNavigation
        locale={locale}
        config={{
          maxWidth: MAX_WIDTH,
          website: 'help',
          homeUrl: 'https://prusa3d.com/',
        }}
      />
    </header>
  );
}

AppHeader.propTypes = {
  nav: PropTypes.Array,
  translations: PropTypes.Object,
};

export default AppHeader;
