export const PERSISTENT_STATE_KEYS = {
    jwt: 'jwt_token',
    user: 'prusuki_current_user',
}

const getCurrentCookies = () => {
    if (!process.browser) return null;

    const currentCookies = document.cookie;
    return currentCookies;
}

const getCookie = ({ key, cookies }) => {
    const serialized_coolies = cookies || getCurrentCookies();

    if (typeof serialized_coolies !== 'string') return null;

    const cookie = serialized_coolies.split(';').reduce((acc, item) => {
        const [key, value] = item.split('=');
        acc[key.trim()] = value;
        return acc;
    }, {});

    const cookieValue = cookie[key];

    try {
        cookieValue = JSON.parse(cookieValue);
    } catch {
        // throw new Error('Cannot parse string');
    }

    return cookieValue;
}

const setCookie = ({ key, value }) => {
    // const currentCookies = getCurrentCookies();
    let cookieValue = value;

    if (typeof cookieValue === 'object') cookieValue = JSON.stringify(cookieValue);

    let expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7 days from now
    const newCookie = `${key}=${cookieValue}; expires=` + expiryDate.toUTCString();

    // const mergedCoolies = newCookie + ';' + currentCookies;

    document.cookie = newCookie;
}

const deleteCookie = ({ key }) => {
    // const currentCookies = getCurrentCookies();

    if (document.cookie.split(';').some((item) => item.includes(`${key}=`))) {
        document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
        console.log(`Cookie with name ${key} is deleted!`);
    } else {
        console.log(`No cookie found with the name ${key}`);
    }
}

export default {
    cookie: {
        get: getCookie,
        set: setCookie,
        delete: deleteCookie,
    },
}