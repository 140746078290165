import styled from 'styled-components';
import { themeColor } from '../../utils/themeHelpers';

export default styled.a`
  padding: 1rem 0;
  border-bottom: 1px solid ${themeColor('lightGrey')};
  transition: color 300ms;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: #333;
  }
`;
