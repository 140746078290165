/**
 * @file ForumWidget
 * @todo refactor getItems functions
 */

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment/min/moment-with-locales';
import { isEqual } from 'lodash';
import { useRouter } from 'next/router';

import messages from './messages';
import IntlText from '../../components/IntlText';
import {
  Col,
  Wrapper,
  H2,
  ButtonBig as Button,
  List,
  ListItem,
  Meta,
} from '../../components/layout';
import { CommentIcon, EyeIcon } from '../../components/layout/Icons';
import { themeColor } from '../../utils/themeHelpers';
import { isBlank } from '../../utils/helpers';

const IMG_SRC = '/img/avatar.jpg';

const Avatar = styled.img`
  width: 80px;
  height: auto;
  border-radius: 50%;
  margin-right: 1.2em;
`;

const Stats = styled.div`
  color: ${themeColor('grey')};
  display: flex;
  justify-content: space-between;
  width: 150px;
  font-size: 1.1em;
  margin-left: auto;
  @media screen and (max-width: 48em) {
    width: 60%;
    margin: auto;
    margin-top: 1em;
  }
`;

const Title = styled.h3`
  font-weight: normal;
  font-size: 1.1em;
  margin: 5px 0;
  color: #333;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  @media screen and (max-width: 48em) {
    justify-content: center;
    text-align: center;
  }
`;

const ForumItems = ({ topics }) => {
  const { locale } = useRouter();
  moment.locale(locale);

  if (!topics?.length) {
    return (
      <p style={{ textAlign: 'center' }}>
        {/* <em>{search}</em> ### */}
        Nothing found.
      </p>
    );
  }

  return topics.map(topic => {
    let topicItem =
      typeof topic === 'object' && 'data' in topic ? topic.data : topic;

    return (
      <ListItem key={topicItem.topicid}>
        <a
          href={topicItem.url}
          target="_blank"
          rel="noreferrer noopener"
          style={{ padding: '0 0.5em', display: 'block' }}
        >
          <Content>
            <Avatar
              src={
                !topicItem.avatar || topicItem.avatar.startsWith('http:')
                  ? IMG_SRC
                  : topicItem.avatar
              }
              alt={topicItem.title}
            />
            <div>
              <Title>{topicItem.title}</Title>
              <Meta>
                <IntlText index={messages.updated} />{' '}
                {moment(topicItem.lastupdate).fromNow()}
                {isBlank(messages.by) && (
                  <>
                    {' '}
                    <IntlText index={messages.by} />
                  </>
                )}
                : {topicItem.nicename}
              </Meta>
            </div>
            <Stats>
              <span>
                <CommentIcon /> {topicItem.postcount}
              </span>
              <span>
                <EyeIcon /> {topicItem.viewscount}
              </span>
            </Stats>
          </Content>
        </a>
      </ListItem>
    );
  });
};

export function ForumWidget({ items: topics }) {
  const onClick = useCallback(() =>
    window.open('https://forum.prusa3d.com', '_blank'),
  );

  if (!topics || topics?.length === 0 || topics?.empty) return null;

  return (
    <Wrapper>
      <H2 center>
        <IntlText index={messages.topPosts} />
      </H2>
      <Col padding>
        <List>
          <ForumItems topics={topics} />
        </List>
      </Col>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={onClick}>
          <IntlText index={messages.visitForum} />
        </Button>
      </div>
    </Wrapper>
  );
}

ForumWidget.propTypes = {
  search: PropTypes.string,
  data: PropTypes.object,
  tag: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

export default React.memo(ForumWidget, (prevProps, nextProps) =>
  isEqual(prevProps.items, nextProps.items),
);
