/*
 * ItemType Messages
 *
 * This contains all the text for the ItemType component.
 */

export const scope = 'app.components.ItemType';

const messages = {
  helps: `${scope}.helps`,
  steps: `${scope}.steps`,
  guides: `${scope}.guides`,
  manuals: `${scope}.manuals`,
  category: `${scope}.category`,
  tooltips: `${scope}.tooltips`,
  post_tag: `${scope}.post_tag`,
  lines: `${scope}.lines`,
};

export default messages;