import { useContext } from 'react';
import { GlobalContext } from '../GlobalProvider/';
import styled, { useTheme } from 'styled-components';
import { H2 } from '../layout';
// import loaderSvg from '../../../../public/app/components/Loader/loader.svg';

const LoaderWrapper = styled.div<{ fullPage: boolean; occupySpace?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: ${props => (props.occupySpace ? '80vh' : '100%')};
  position: ${props => (props.fullPage ? 'absolute' : 'relative')};
  ${props => !props.fullPage && 'margin: 1em auto;'}
`;

const RotatingSvg = styled.svg`
  animation: rotate 1.5s linear infinite;

  @keyframes rotate{
    to{ transform: rotate(360deg); }
  }
`;

interface LoaderProps {
  fullPage?: boolean;
  globalStateLoader: boolean;
  occupySpace?: boolean;
  size?: number;
}

const Loader: React.FC<LoaderProps> = ({
  fullPage = false,
  globalStateLoader = false,
  occupySpace = false,
  size = 100,
}) => {
  const { isPreview } = useContext(GlobalContext);
  const theme = useTheme();

  return (
    <LoaderWrapper fullPage={fullPage} occupySpace={occupySpace}>
      {globalStateLoader && isPreview && <H2>Asking Jo for approval.</H2>}
      <RotatingSvg width={size} height={size} viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke={(theme as any)?.colors?.main || 'black'} aria-label="audio-loading"><g fill="none" fillRule="evenodd"><g transform="translate(1 1)" strokeWidth="2"><circle strokeOpacity=".5" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18"></path></g></g></RotatingSvg>
    </LoaderWrapper>
  );
};

export default Loader;
