export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const PLACEHOLDER_SRC =
  '/wp-content/uploads/prusuki/prusuki-images/UswO631d2Jk1YNGk.jpg';

export { default as STATUS_MAP } from './constants/statusMap';

export const allowedLangs = ['en', 'cs', 'pl', 'de', 'ja', 'es', 'it', 'fr'];
export const translationLangs = [
  'en',
  'cs',
  'de',
  'es',
  'fr',
  'it',
  'ja',
  'pl',
];

/**
 * @todo take from global props (/info endpoint)
 */
export const ROLES = {
  ADMIN: 'administrator',
  COMMENT_ADMIN: 'comments_administrator',
  AUTHOR: 'editor',
  EMPLOYEE: 'employee',
  MEDIC: 'medic',
  MEDIC_SERVICE_TECHNICIAN: 'medical_support',
  TESTER: 'tester',
};

export const MAX_WIDTH = '81.75rem';

export const SHOULD_USE_CDN =
  process.env.NEXT_PUBLIC_ORIGIN === 'https://help.prusa3d.com';

export const CDN_URL = 'https://cdn.help.prusa3d.com';
