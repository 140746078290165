import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const GeneralIcon = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  margin: 0 0.2em;
`;

export const EyeIcon = ({ width = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    fill="currentColor"
    // height="16"
    viewBox="0 0 24 16"
    style={{
      verticalAlign: 'middle',
      display: 'inline-block',
      margin: '0 0.2em',
    }}
  >
    <path
      className="a"
      d="M12,73.333A2.667,2.667,0,0,0,12,68c-.042,0-.078.01-.119.012a1.979,1.979,0,0,1-2.536,2.536c0,.042-.012.078-.012.119A2.667,2.667,0,0,0,12,73.333Zm11.855-1.942A13.364,13.364,0,0,0,12,64,13.366,13.366,0,0,0,.146,71.392a1.348,1.348,0,0,0,0,1.216A13.364,13.364,0,0,0,12,80a13.366,13.366,0,0,0,11.855-7.392,1.348,1.348,0,0,0,0-1.216ZM12,65.333a5.333,5.333,0,1,1-5.333,5.333A5.333,5.333,0,0,1,12,65.333Zm0,13.333A12.011,12.011,0,0,1,1.334,72a12.282,12.282,0,0,1,5.408-5.389,6.656,6.656,0,1,0,11.926,4.055,6.606,6.606,0,0,0-1.408-4.055A12.283,12.283,0,0,1,22.667,72,12.01,12.01,0,0,1,12,78.667Z"
      transform="translate(0 -64)"
    />
  </svg>
);

EyeIcon.propTypes = {
  width: PropTypes.number,
};

export const CommentIcon = ({ width = 20, style }) => (
  <svg
    fill="currentColor"
    width={width}
    viewBox="0 0 511.626 511.627"
    style={{
      verticalAlign: 'middle',
      display: 'inline-block',
      margin: '0 0.2em',
      ...style,
    }}
  >
    <g>
      <path
        d="M477.364,127.481c-22.839-28.072-53.864-50.248-93.072-66.522c-39.208-16.274-82.036-24.41-128.479-24.41
        c-46.442,0-89.269,8.136-128.478,24.41c-39.209,16.274-70.233,38.446-93.074,66.522C11.419,155.555,0,186.15,0,219.269
        c0,28.549,8.61,55.299,25.837,80.232c17.227,24.934,40.778,45.874,70.664,62.813c-2.096,7.611-4.57,14.842-7.426,21.7
        c-2.855,6.851-5.424,12.467-7.708,16.847c-2.286,4.374-5.376,9.23-9.281,14.555c-3.899,5.332-6.849,9.093-8.848,11.283
        c-1.997,2.19-5.28,5.801-9.851,10.848c-4.565,5.041-7.517,8.33-8.848,9.853c-0.193,0.097-0.953,0.948-2.285,2.574
        c-1.331,1.615-1.999,2.419-1.999,2.419l-1.713,2.57c-0.953,1.42-1.381,2.327-1.287,2.703c0.096,0.384-0.094,1.335-0.57,2.854
        c-0.477,1.526-0.428,2.669,0.142,3.429v0.287c0.762,3.234,2.283,5.853,4.567,7.851c2.284,1.992,4.858,2.991,7.71,2.991h1.429
        c12.375-1.526,23.223-3.613,32.548-6.279c49.87-12.751,93.649-35.782,131.334-69.094c14.274,1.523,28.074,2.283,41.396,2.283
        c46.442,0,89.271-8.135,128.479-24.414c39.208-16.276,70.233-38.444,93.072-66.517c22.843-28.072,34.263-58.67,34.263-91.789
        C511.626,186.154,500.207,155.555,477.364,127.481z M445.244,292.075c-19.896,22.456-46.733,40.303-80.517,53.529
        c-33.784,13.223-70.093,19.842-108.921,19.842c-11.609,0-23.98-0.76-37.113-2.286l-16.274-1.708l-12.277,10.852
        c-23.408,20.558-49.582,36.829-78.513,48.821c8.754-15.414,15.416-31.785,19.986-49.102l7.708-27.412l-24.838-14.27
        c-24.744-14.093-43.918-30.793-57.53-50.114c-13.61-19.315-20.412-39.638-20.412-60.954c0-26.077,9.945-50.343,29.834-72.803
        c19.895-22.458,46.729-40.303,80.515-53.531c33.786-13.229,70.089-19.849,108.92-19.849c38.828,0,75.13,6.617,108.914,19.845
        c33.783,13.229,60.62,31.073,80.517,53.531c19.89,22.46,29.834,46.727,29.834,72.802S465.133,269.615,445.244,292.075z"
      />
    </g>
  </svg>
);

CommentIcon.propTypes = {
  width: PropTypes.number,
};
