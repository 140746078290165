/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

export const scope = 'app.components.AppHeader';

const messages = {
	login: `${scope}.login`,
	logout: `${scope}.logout`,
	myProfile: `${scope}.myProfile`,
};

export default messages;
