import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { themeColor } from '../../utils/themeHelpers';

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  border-left: 1px solid ${themeColor('lightGrey')};
  height: 100vh;
  text-align: left;
  padding: 2rem 1rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};

  @media (max-width: 48em) {
    width: 50%;
  }
`;

const Menu = ({ open, children, forwardRef }) => (
  <StyledMenu open={open} ref={forwardRef}>{children}</StyledMenu>
);

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default Menu;
