import styled from 'styled-components';
import { themeColor } from '../../utils/themeHelpers';

export default styled.div`
  height: 40px;
  width: 100%;
  padding: 0 1em;
  position: fixed;
  background-color: ${props => (props.isTest === 'true' ? 'rgb(245, 245, 245)' : 'white')};
  border-bottom: 1px solid ${themeColor('lightGrey')};
  align-items: center;
  top: 0;
  z-index: 2000;
  contain: style size layout;
  
  @media screen and (max-width: 64em) {
    justify-content: center;
  }
  @media print {
    display: none;
  }
`;
