import styled from 'styled-components';

export default styled.a`
  display: inline-block;
  color: #333;
  cursor: pointer;
  border: 1px solid #333;
  padding: 0.325rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.125rem;
  vertical-align: middle;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #333;
  }
`;
