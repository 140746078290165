/**
 * @file TagBadge
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import { themeColor } from '../../utils/themeHelpers';
import { urlBuilder } from '../../utils/helpers';

export const Tag = styled.span`
  color: #808080;
  border: 1px solid ${themeColor('lightGrey')};
  border-radius: 2px;
  margin-right: 0.2em;
  margin-bottom: 0.2em;
  padding: 0.2em;
  font-size: 0.8em;
  word-break: keep-all;
  white-space: nowrap;
  transition: all 400ms;
  cursor: pointer;
  display: inline-block;
  &:hover {
    background-color: ${themeColor('main')};
    color: white;
    border-color: ${themeColor('main')};
  }
  ${({ active, theme }) =>
    active
      ? `
    background-color: ${props => props.theme.colors.main};
    color: white !important;
    border-color: ${props => props.theme.colors.main} !important;
  `
      : `
    background-color: #f5f5f5;
  `}
`;

function TagBadge({ tag, language }) {
  if (!tag) return null;
  return (
    <Link href={urlBuilder({ item: { ...tag, type: 'tag' } })}>
      <a>
        <Tag>{tag.title}</Tag>
      </a>
    </Link>
  );
}

export const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
  width: 100%;
  line-height: 1.9rem;
`;

TagBadge.propTypes = {
  tag: PropTypes.object.isRequired,
};

export default TagBadge;
