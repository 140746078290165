/**
 * @file ContactSection
 * @todo safer way to insert email?
 */

import styled from 'styled-components';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { H2, ColorBar, Wrapper, ButtonWhiteA } from '../layout';
import IntlText from '../IntlText';
import messages from './messages';
import { usePruslation } from '../../utils/hooks';

const ImageWrapper = styled.div`
  margin-right: 1em;
  display: inline-flex;
  width: 50%;
`;

const CustomGrid = styled.div`
  display: flex;
  @media screen and (max-width: 48em) {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
`;

const ContactUsButton = styled(ButtonWhiteA)`
  text-transform: none;
  padding: 1rem 1.7rem 0.9rem 1.7rem;
  font-size: 1.1rem;

  &:hover {
    background: rgb(224, 224, 224);
    text-decoration: none !important;
  }
`;

const LOCALE_TO_URL = {
  en: 'https://www.prusa3d.com/page/ask-us-anything_482/',
  cs: 'https://www.prusa3d.com/cs/stranka/zeptejte-se-nas-na-cokoli_482/',
  de: 'https://www.prusa3d.com/de/page/fragen-sie-uns-alles_482/',
  es: 'https://www.prusa3d.com/es/pagina/preguntanos-lo-que-quieras_482/',
  fr: 'https://www.prusa3d.com/fr/page/demandez-nous-nimporte-quoi-_482/',
  it: 'https://www.prusa3d.com/it/pagina/chiedeteci-qualsiasi-cosa_482/',
  pl: 'https://www.prusa3d.com/pl/strona/zapytaj-nas-o-cokolwiek_482/',
  ja: 'https://www.prusa3d.com/page/ask-us-anything_482/',
};

function ContactSection() {
  const { locale } = useRouter();
  const { t } = usePruslation();

  return (
    <ColorBar>
      <Wrapper width={600}>
        <CustomGrid>
          <ImageWrapper>
            <Image
              src="/img/question.svg"
              alt={t(messages.header)}
              width={143}
              height={221}
            />
          </ImageWrapper>
          <div>
            <H2>
              <IntlText index={messages.header} />
            </H2>
            <p>
              <IntlText index={messages.haveQuestions} />
              <br />
              <IntlText
                index={messages.contactUsText}
                components={{ strong: <strong /> }}
              />
            </p>
            <ContactUsButton
              href={LOCALE_TO_URL[locale]}
              style={{ display: 'inline-block' }}
            >
              <IntlText index={messages.contactUs} />
            </ContactUsButton>
          </div>
        </CustomGrid>
      </Wrapper>
    </ColorBar>
  );
}

ContactSection.propTypes = {};

export default ContactSection;
