/**
 * @file Predefined sets of options for fetching.
 * @property {function} canFetch - Condition whether or not can fetch data. Used for SWR. Should return boolean.
 * @property {boolean} extractDataProp - Whether get only the 'data' prop of fetched JSON or whole JSON. Default: true.
 * @property {object} options - SWR options.
 * @property {function} middleware - Additional processing of fetched data.
 */
import { endpoints } from './endpoints';

export const endpointPresets = language => ({
  info: {
    endpoint: endpoints.prusuki.info,
    query: {},
  },
  user: {
    canFetch: () => process.browser,
    endpoint: endpoints.prusuki.user,
    query: {},
    options: {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
  },
  categories: {
    endpoint: endpoints.prusuki.categories,
    query: {
      lng: language,
    },
  },
  search: {
    endpoint: endpoints.prusuki.search,
    query: {
      search: '',
      lng: language,
      per_page: 20,
      type: ['categories', 'guides', 'helps', 'tooltips'].join(','),
    },
  },
  vector_search: {
    endpoint: endpoints.prusuki.vector_search,
    query: {
      query: '',
    },
  },
  helps: {
    endpoint: endpoints.prusuki.helps,
    query: {
      lng: language,
    },
  },
  allHelps: {
    endpoint: endpoints.prusuki.helps,
    query: {
      lng: language,
    },
  },
  guides: {
    endpoint: endpoints.prusuki.guides,
    extractDataProp: false,
    query: {
      lng: language,
    },
  },
  steps: {
    endpoint: endpoints.prusuki.steps,
    query: {
      lng: language,
    },
  },
  downloads: {
    endpoint: endpoints.prusuki.downloads,
    query: {
      lng: language,
      orderby: 'created',
      order: 'asc',
    },
  },
  forumById: {
    endpoint: endpoints.forum.id,
    extractDataProp: false,
    query: {
      lang: language,
      page: 1,
      per_page: 5,
    },
  },
  topics: {
    endpoint: endpoints.forum.search,
    extractDataProp: false,
    query: {
      lang: language,
      page: 1,
      per_page: 5,
    },
  },
  topicsByTag: {
    endpoint: endpoints.forum.tags,
    extractDataProp: false,
    query: {
      lang: language,
      page: 1,
      per_page: 5,
    },
  },
  related: {
    endpoint: endpoints.prusuki.related,
    query: {
      lng: language,
    },
  },
  tooltips: {
    endpoint: endpoints.prusuki.tooltips,
    query: {
      lng: language,
    },
  },
  materials: {
    endpoint: endpoints.prusuki.materials,
    extractDataProp: false,
    query: {
      lng: language,
    },
  },
  pdfs: {
    endpoint: endpoints.prusuki.pdfs,
    extractDataProp: false,
    query: {
      lng: language,
    }
  },
  redirects: {
    endpoint: endpoints.prusuki.redirects,
    extractDataProp: true,
    query: {
      lng: language,
    }
  },
  profiles: {
    endpoint: endpoints.prusuki.profiles,
    extractDataProp: false,
    query: {},
  },
});
