import styled from 'styled-components';
import { themeColor } from '../../utils/themeHelpers';

export default styled.ul`
  display: ${props => props.open ? 'block' : 'none'};
  position: absolute;
  left: auto;
  right: 5px;
  top: 35px;
  min-width: 158px;
  background-color: white;
  border: 1px solid ${themeColor('grey')};
  padding: 0;
  margin: 0;
  text-align: left;
  li {
    padding-left: 1em;
    padding-right: 1em;
  }

  @media screen and (min-width: 1536px) {
    right: -110px;
  }
`;
