import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import MainFooter from '@prusa3d-platform/prusa-main-footer';
import TagManager from 'react-gtm-module';

import messages from './messages.HomePage';
import IntlText from '../app/components/IntlText';
import Link from 'next/link';
import ScrollToTop from '../app/components/ScrollToTop';
import AppHeader from '../app/components/AppHeader';
import { Wrapper, ColorBar } from '../app/components/layout';
import SearchInput from '../app/containers/Search';
import ContactSection from '../app/components/ContactSection';
import GreyWrapper from '../app/containers/Search/GreyWrapper';
import Loader from '../app/components/Loader';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { MAX_WIDTH } from '../app/utils/constants';

const MainContent = styled.section`
  position: relative;
  min-height: calc(100vh - (95px + 48px + 2em));
  max-width: 100vw;
`;

const LinksWrapper = styled.div`
  text-align: center;
  margin-top: 1em;
  font-size: 0.8em;

  a {
    color: white;
    text-decoration: underline;
  }

  @media only screen and (max-width: 770px) {
    color: ${({ theme }) => theme.colors.main};
  }
`;

const FeaturedLink = styled.a`
  @media only screen and (max-width: 770px) {
    background: white;
    display: inline-block;
    padding: 0.5em 0.7em 0.3em 0.7em;
    color: ${({ theme }) => theme.colors.main} !important;
    text-decoration: none !important;
    margin-bottom: 0.5em;
  }
`;

const logToTagManager = async (payload) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'click_search_result',
      ...payload,
    },
  });
};

function PageLayout({
  global,
  children,
  defaultValue,
  withHeader,
  withLinks,
  withoutContactSection = false,
  stickySearch = true,
  translations,
}) {
  const [getLoadingRoute, setLoadingRoute] = useState(false);
  const router = useRouter();
  const timeIdRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleRouteChangeStart = React.useCallback(async (url) => {
    timeIdRef.current = setTimeout(() => setLoadingRoute(true), 2000);

    const currentPath = router.asPath;
    if (currentPath.includes('/search?') && (url.includes('/article') || url.startsWith('/downloads') || url.startsWith('/tag') || url.includes('/guide') || url.includes('/category'))) {
      const payload = {
        fromPage: router.locale === 'en' ? currentPath : `/${router.locale}/${currentPath}`,
        toPage: url,
        searchPhrase: router.query?.s,
      }
      await logToTagManager(payload);
    }
  }, [router.asPath, router.locale, router.query?.s]);

  const handleRouteChangeComplete = React.useCallback(async () => {
    clearTimeout(timeIdRef.current);
    setLoadingRoute(false);
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [handleRouteChangeStart, handleRouteChangeComplete]);

  useEffect(() => {
    if (getLoadingRoute && !window.location.hash) window.scrollTo(0, 0);
  }, [getLoadingRoute]);

  return (
    <>
      <AppHeader nav={global?.data?.nav} translations={translations} />
      <MainContent>
        <ScrollToTop />
        {withHeader ? (
          <ColorBar center height={200}>
            <Wrapper style={{ position: 'inherit', width: '100%' }}>
              <h1>
                <IntlText index={messages.header} />
              </h1>
              <SearchInput defaultValue={defaultValue} height={200} global={global} />
              {withLinks && (
                <LinksWrapper>
                  <Link href={`/article/${global?.data?.slicer_link}`} passHref>
                    <FeaturedLink>
                      <IntlText index={messages.slicer} />
                    </FeaturedLink>
                  </Link>
                  &nbsp;|&nbsp;
                  <Link href="/article/prusa-connect-and-prusalink-explained_302608 " passHref>
                    <FeaturedLink>
                      <IntlText index={messages.connect} />
                    </FeaturedLink>
                  </Link>
                  &nbsp;|&nbsp;
                  <Link href="/materials" passHref>
                    <FeaturedLink>
                      <IntlText index={messages.filamentGuide} />
                    </FeaturedLink>
                  </Link>
                  &nbsp;|&nbsp;
                  <Link href="/downloads" passHref>
                    <FeaturedLink>
                      <IntlText index={messages.downloads} />
                    </FeaturedLink>
                  </Link>
                </LinksWrapper>
              )}
            </Wrapper>
          </ColorBar>
        ) : (
            <GreyWrapper isSticky={stickySearch} global={global} />
        )}
        {getLoadingRoute ? <Loader occupySpace /> : children}
        {!withoutContactSection && <ContactSection />}
      </MainContent>
      <MainFooter
        locale={router.locale}
        config={{
          maxWidth: MAX_WIDTH,
          website: 'help',
          homeUrl: 'https://prusa3d.com/',
          parentHasRecaptchaProvider: true,
          externalExecuteRecaptcha: executeRecaptcha,
        }}
        LinkComponent={Link}
      />
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  translations: PropTypes.Object,
};

export default PageLayout;
