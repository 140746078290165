export const servers = {
  prusuki: process.browser
    ? `${process.env.NEXT_PUBLIC_ORIGIN}/`
    : process.env.WP_API_SERVER,
  forum: process.env.NEXT_PUBLIC_WP_API_FORUM,
  slicer: process.env.SLICER_PROFILES_URL || '',
};

export const basepath = {
  v1: `${servers.prusuki}api/v1`,
  prusuki: `${servers.prusuki}api/prusuki`,
  forum: servers.forum,
  vector_search: `${servers.prusuki}/search-index`,
  slicer: `${servers.slicer}v1/repos`,
};

export const endpoints = {
  prusuki: {
    search: `${basepath.v1}/search`,
    info: `${basepath.v1}/info`,
    user: process.browser
      ? `${servers.prusuki}next/xJ7kQ6`
      : `${basepath.prusuki}/user`,
    logout: `${basepath.prusuki}/logout`,
    categories: `${basepath.v1}/categories`,
    helps: `${basepath.v1}/helps`,
    search: `${basepath.v1}/search`,
    vector_search: `${basepath.vector_search}/search`,
    guides: `${basepath.v1}/guides`,
    steps: `${basepath.v1}/steps`,
    downloads: `${basepath.v1}/prusa3d_downloads`,
    related: `${basepath.v1}/related`,
    materials: `${basepath.v1}/materials`,
    tooltips: `${basepath.v1}/tooltips`,
    pdfs: `${basepath.v1}/pdfs`,
    redirects: `${basepath.v1}/get_redirect`,
    profiles: `${basepath.slicer}`,
  },
  forum: {
    id: `${basepath.forum}id`,
    tags: `${basepath.forum}tags`,
    search: `${basepath.forum}search`,
  },
};
