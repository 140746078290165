/**
 * @file Load text in proper language and namespace and format it.
 * @todo FormattedMessage
 * @todo inserted values
 */
import React from 'react';
import PropTypes from 'prop-types';
import { usePruslation } from '../../utils/hooks';
import Trans from 'next-translate/Trans';

export { usePruslation };

const getTranslatedText = ({index, values, components}) => {
  let text = '';
  const { t, namespace } = usePruslation();
  
  if (components) {
    const option = (process.env.NODE_ENV === "development" || process.env.TESTING_BUILD === 'true') ? '🐛' : '';
    // const key =
      // index.split(':').length > 1 ? `${index}` : `${namespace}:${index}`;
    text = <Trans i18nKey={`${namespace}:${index}`} components={components} values={values} defaultTrans={option} />;
  } 
  else 
    text = `${t(index, values, { fallback: '' })}`;

  return text;
}

const IntlText = ({ index, values = {}, components = null }) => {
  const text = getTranslatedText({ index, values, components })
  return <>{text}</>;
};

IntlText.propTypes = {
  NAMESPACE: PropTypes.string,
  index: PropTypes.string.isRequired,
  values: PropTypes.object,
  components: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default IntlText;
