/*
 * ContactSection Messages
 *
 * This contains all the text for the ContactSection component.
 */

export const scope = 'app.components.ContactSection';

const messages = {
  header: `${scope}.header`,
  haveQuestions: `${scope}.haveQuestions`,
  contactUsText: `${scope}.contactUsText`,
  contactUs: `${scope}.contactUs`,
};

export default messages;
