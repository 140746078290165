/**
 *
 * LanguageSwitcher
 *
 */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { GlobalContext } from '../GlobalProvider/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { themeColor } from '../../utils/themeHelpers';
import { langMap } from './icons';

const List = styled.ul`
  position: absolute;
  right: 0;
  top: 20px;
  margin: 0;
  padding: 0 0.4rem;
  box-sizing: border-box;
  max-height: 0;
  display: none;
  list-style: none;
  background-color: white;
  border-radius: 2px;
  width: 100px;
  transition: max-height 200ms;
  overflow: hidden;
  &:hover {
    display: block;
  }
  li {
    cursor: pointer;
    border-bottom: 1px solid ${themeColor('lightGrey')};
    padding: 0.5rem;
  }
`;

const MobileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    width: 25%;
    // border-left: 1px solid ${themeColor('lightGrey')};
    padding: 3px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  width: ${props => (props.mobile ? 'auto' : '30px')};
  &:hover {
    ${List} {
      border: 1px solid ${themeColor('grey')};
      max-height: 320px;
      display: block;
    }
  }
`;

const Li = styled.li`
  ${props =>
    props.current
      ? `
    border: 1px solid #333;
    border-radius: 0.125rem;
  `
      : `
    &:hover {
      color: #666;
    }
  `}
`;

const LiClassic = styled.li`
  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
  &:hover {
    font-weight: bold;
  }
`;

function LanguageSwitcher({ mobile = false, translations }) {
  const { isPreview } = useContext(GlobalContext);
  const router = useRouter();

  const ListComp = mobile ? MobileList : List;
  const LiComp = mobile ? Li : LiClassic;

  const setLanguageChoiceMemo = React.useCallback((locale) => {
    localStorage.setItem('prusuki_language_choice', locale);
  }, []);

  return (
    <Wrapper mobile={mobile}>
      <span
        style={{
          position: 'relative',
          display: 'inline-block',
          color: mobile ? '#999' : 'inherit',
          width: '40px',
          cursor: 'pointer',
        }}
      >
        {!mobile && (
          <>
            {router.locale.toUpperCase()}
            <FontAwesomeIcon
              icon={faAngleDown}
              style={{ width: '0.5rem', marginLeft: '5px', display: 'inline' }}
            />
          </>
        )}
      </span>
      <ListComp>
        {router.locales.map(locale => {
          if (!mobile && locale === router.locale) return null;
          // Pages with dynamic path provide object with translations containining proper slug.
          const translation = translations?.[locale];

          if (!translation && locale === 'ja') return null; // Exclude Japanese in case of pages with static path.
          if (translation && !isPreview && translation?.translated != 1) return null; // Exclude not translated language mutations for dynamic paths.

          const href = (translation) ? router.asPath.replace(/\/[^\/]+?$/, `/${translation.slug}`) : router.asPath;

          return (
            <Link href={href} locale={locale} key={locale} passHref>
              <LiComp onClick={() => setLanguageChoiceMemo(locale)}>
                {/* <img src={iconMap[lang]} title={lang.toUpperCase()} alt={lang.toUpperCase()} /> */}
                {mobile ? locale.toUpperCase() : langMap[locale]}
              </LiComp>
            </Link>
          );
        })}
      </ListComp>
    </Wrapper>
  );
}

LanguageSwitcher.propTypes = {
  mobile: PropTypes.bool,
  translations: PropTypes.Object,
};

export default React.memo(LanguageSwitcher, (prevProps, nextProps) => {
  prevProps.mobile === nextProps.mobile;
});
