import styled from 'styled-components';
import Link from 'next/link';
import { Image } from '../layout';

import { PLACEHOLDER_SRC } from '../../utils/constants';
import { Card, Title } from '../layout/Card';
import { StatusBadge } from '../layout';

const Content = styled(Link)`
  color: white;
  z-index: 12;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

function Tag({ slug, media, content, status }) {
  return (
    <Card>
      <Content href={`/tag/${slug}/`}>
        <a>
          {status === 'private' && <StatusBadge status={status} />}
          <Image
            image={media?.image}
            placeholder={PLACEHOLDER_SRC}
            size="medium"
            alt={content}
            width={264}
            height={264}
          />
          <Title>{content}</Title>
        </a>
      </Content>
    </Card>
  );
}

export default Tag;
