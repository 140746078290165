import styled from 'styled-components';

import { themeColor } from '../../utils/themeHelpers';

export default styled.div`
  font-size: 0.8rem;
  color: #b3b3b3;
  a {
    color: ${themeColor('main')};
  }
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  max-height: 100vh;
  min-width: 250px;
  padding-right: ${props => (props.isLong ? '13px' : '10px')};
  overflow-x: visible;
  overflow-y: auto;
  @media screen and (max-width: 64em) {
    position: absolute;
    background-color: white;
    border: 1px solid ${themeColor('lightGrey')};
    top: 0 !important;
    padding: 4em 1em 1em;
    z-index: 40;
    transform: ${({ open }) =>
      open ? 'translateX(-1rem)' : 'translateX(-300px)'};
  }
  &:hover {
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #fcc1ab;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #fa6831;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #fa6831;
  }
  &::-webkit-scrollbar-track {
    background: #eeeeee;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #eeeeee;
  }
  &::-webkit-scrollbar-track:active {
    background: #eeeeee;
  }
`;
