/**
 * @file Home Page
 */
import React, { useState, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import messages from './messages.HomePage';
import { usePruslation } from '../app/utils/hooks';
import fetch from '../data/restApi/fetchApi';
import withGlobalData from './_globalProps';
import IntlText from '../app/components/IntlText';
import {
  Wrapper,
  Arrow,
  Grid,
  Col,
  WhiteBar,
  List,
  ListItem,
  ButtonBig as Button,
  ButtonA,
  H2,
} from '../app/components/layout';
import Tag from '../app/components/Tag';
import FeaturedList from '../app/components/FeaturedList';
import ForumWidget from '../app/containers/ForumWidget';
import SEOHeader from '../app/components/SEOHeaders';
import PageLayout from './_pageLayout';
import { parseSanitizedHTML } from '../app/utils/helpers';
import getServerData from './getHomePageServerData';
import fetchUserForSsr from '../data/restApi/fetchUserForSsr';

const NUMBER_OR_VISIBLE_TAGS = 8;

export function HomePage({ forumData, swrFallback, global }) {
  const { t } = usePruslation();
  const [showAll, setShowAll] = useState(false);
  const router = useRouter();
  const languageRef = useRef(router.locale);

  const { data: articles, isLoading: isLoadingArticles } = fetch.helps.withSWR({
    fallback: swrFallback,
  });

  const { data: categories } = fetch.categories.withSWR({
    fallback: swrFallback,
  });

  const tagsVisibleAtHomepage = React.useMemo(
    () => global?.data?.tags?.filter(tag => tag?.meta?.hp_visibility),
    [global?.data?.tags?.length],
  );

  if (!global?.data) throw new Error('Global data is not available');

  return (
    <PageLayout global={global} withHeader withLinks>
      <SEOHeader
        description={t(messages.description)}
        image={{ original: `/assets/promo.jpg` }}
      />

      <div style={{ paddingBottom: '1em' }}>
        <Wrapper style={{ minHeight: '400px' }}>
          <H2 center>
            <IntlText index={messages.supportByPrinter} />
          </H2>
          {tagsVisibleAtHomepage && (
            <>
              <Grid>
                {tagsVisibleAtHomepage.slice(0, NUMBER_OR_VISIBLE_TAGS).map(tag => (
                  <Tag {...tag} key={tag.slug} />
                ))}
                {showAll &&
                  tagsVisibleAtHomepage
                    .slice(NUMBER_OR_VISIBLE_TAGS)
                    .map(tag => <Tag {...tag} key={tag.slug} />)}
              </Grid>
              {!showAll && (
                <div style={{ textAlign: 'center' }}>
                  <Button onClick={() => setShowAll(true)}>
                    <IntlText index={messages.showAllPrinters} />
                  </Button>
                </div>
              )}
            </>
          )}
        </Wrapper>
      </div>
      <WhiteBar borderBottom borderTop>
        <Wrapper>
          <Grid>
            <Col cols={6} padding>
              <H2>
                <IntlText index={messages.popularCategories} />
              </H2>

              <List>
                {categories?.map(cat => {
                  if (global.data.assembly_categories?.includes(cat.id)) {
                    return null;
                  }
                  return (
                    <ListItem key={cat.id} icon={<Arrow />}>
                      <Link href={`/category/${cat.slug}`}>
                        <a>
                          {parseSanitizedHTML(cat.title)}
                          <br />
                          <span style={{ color: '#999', fontSize: '0.9em' }}>
                            {cat.content}
                          </span>
                        </a>
                      </Link>
                    </ListItem>
                  );
                })}
              </List>
            </Col>
            <Col cols={6} padding>
              <H2>
                <IntlText index={messages.faqs} />
              </H2>
              <FeaturedList
                items={articles}
                isLoading={isLoadingArticles}
                language={languageRef}
                tags={global.tagsBySlug}
                categories={categories}
              />
              <div style={{ textAlign: 'center' }}>
                <Link href="/category/all#articles" passHref>
                  <ButtonA>
                    <IntlText index={messages.allFaqs} />
                  </ButtonA>
                </Link>
              </div>
            </Col>
          </Grid>
        </Wrapper>
      </WhiteBar>
      <div>
        <ForumWidget items={forumData} />
      </div>
    </PageLayout>
  );
}

export async function getServerSideProps({ locale, req }) {
  const startTime = Date.now();

  const { jwt, cookie, medical } = await fetchUserForSsr({ req });

  const serverData = await getServerData({
    locale: locale,
    cookie,
    jwt,
    medical,
  });

  const msElapsed = Date.now() - startTime;
  await console.log(
    '### perf - sec. to process getServerData:',
    msElapsed / 1000,
  );

  return {
    props: serverData,
  };
}

export default withGlobalData(HomePage);
