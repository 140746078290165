/*
 * ErrorBoundary Messages
 *
 * This contains all the text for the ErrorBoundary component.
 */

export const scope = 'app.components.ErrorBoundary';

const messages = {
  main: `${scope}.main`,
  backToHomepage: `${scope}.backToHomepage`,
};

export default messages;