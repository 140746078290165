import styled from 'styled-components';

export default styled.div`
  max-width: ${props => props.width || 1154}px;
  margin: 0 auto;
  padding: 1em;
  position: relative;
  @media screen and (max-width: 48em) {
    padding: 1em 0.5em;
  }
`;
