module.exports = {
  locales: ['cs', 'en', 'es', 'it', 'de', 'pl', 'fr', 'ja'],
  defaultLocale: 'en',
  loader: false,
  keySeparator: false,
  interpolation: { prefix: '{', suffix: '}' },
  pages: {
    '*': ['common'],
  },
  loadLocaleFrom: (lang, namespace) => {
    switch (lang) {
      case 'cs':
        return import('./src/data/i18n/cs.json').then(m => m.default);
      case 'en':
        return import('./src/data/i18n/en.json').then(m => m.default);
      case 'es':
        return import('./src/data/i18n/es.json').then(m => m.default);
      case 'it':
        return import('./src/data/i18n/it.json').then(m => m.default);
      case 'de':
        return import('./src/data/i18n/de.json').then(m => m.default);
      case 'pl':
        return import('./src/data/i18n/pl.json').then(m => m.default);
      case 'fr':
        return import('./src/data/i18n/fr.json').then(m => m.default);
      case 'ja':
        return import('./src/data/i18n/ja.json').then(m => m.default);
      default:
        return import('./src/data/i18n/en.json').then(m => m.default);
    }
  },
};
