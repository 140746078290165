import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import STATUS_MAP from '../../utils/constants/statusMap';
import THEME from "../../utils/constants/theme";


const Badge = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${props => props.theme.colors.main};
  padding: 5px;
  color: white;
  font-size: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom-right-radius: 5px;
  z-index: 45;
`;

export function StatusBadge({ status }: {status: keyof typeof STATUS_MAP}) {
  return <Badge>{STATUS_MAP[status]}</Badge>;
}

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Badge;
