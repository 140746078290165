import React from 'react';
import PropTypes from 'prop-types';

const map = {
  de: ' (Übersetzung in Arbeit)',
  es: ' (Traducción en desarrollo)',
  fr: ' (Traduction en cours)',
  pl: ' (Tłumaczenie w toku)',
  it: ' (Traduzione in corso)',
  ja: ' (進行中の翻訳)',
  cs: ' (Překládá se)',
};

function TranslationStatus({ status, language }) {
  if (
    language !== 'en' &&
    status &&
    (status.title === 0 || status.content === 0)
  ) {
    return (
      <span style={{ fontWeight: 'normal', fontSize: '0.9em' }}>
        {` ${map[language]}`}
      </span>
    );
  }
  return null;
}

TranslationStatus.propTypes = {
  status: PropTypes.object,
  language: PropTypes.string.isRequired,
};

export default TranslationStatus;
