import moment from 'moment';
import {
  useState,
  useLayoutEffect,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import _ from 'lodash';
import { isBlank } from './helpers'
import fetch from '../../data/restApi/fetchApi'
import * as Sentry from '@sentry/nextjs';

import { compareDates, debounce } from './helpers';
import request from './request';
// import path2namespace from '../../data/i18n/namespaces.json';

const extractKey = keyWithNamespace =>
  keyWithNamespace.split(':')?.[1] || keyWithNamespace;

// Set the default namespace based on the current pathname.
export function usePruslation(namespace = 'common') {
  // const router = useRouter();
  // const namespaceRef = useRef(
  //   namespace ||
  //     _.chain(path2namespace)
  //       .get(router.pathname)
  //       .first()
  //       .value(),
  // );
  const { t, lang } = useTranslation(namespace); // namespaceRef.current
  const option = (process.env.NODE_ENV === "development" || process.env.NEXT_PUBLIC_TESTING_BUILD === 'true') ? { default: '🐛' } : {};

  return {
    t: (key, query = {}) =>
      t(key, query, option), // { fallback: `common:${extractKey(key)}` } ###
    lang,
    namespace, //: namespaceRef.current,
  };
}

export function useModal(initialState = false) {
  const [open, setOpen] = useState(initialState);

  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);

  return [open, openModal, closeModal];
}

function useStorage(key, initialValue, storage) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (!process.browser) return initialValue;

    if (!storage) storage = window.localStorage;
    try {
      // Get from local storage by key
      const item = storage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      storage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useLocalStorage(key, initialValue, _) {
  const storage = process.browser ? window.localStorage : {};
  return useStorage(key, initialValue, storage);
}

export function useSessionStorage(key, initialValue, _) {
  const storage = process.browser ? window.localStorage : {};
  return useStorage(key, initialValue, storage);
  x;
}

const isBrowser = typeof window !== `undefined`;

function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target ? target.getBoundingClientRect() : {};

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export function useScrollPosition(effect, deps, element, useWindow, wait) {
  if (!process.browser) return null;

  const position = useRef(getScrollPosition({ useWindow }));

  let throttleTimeout = null;

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow });
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait);
        }
      } else {
        callBack();
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

export const useMediaQuery = query => {
  const [match, setMatch] = useState(false);

  useEffect(() => {
    const updateMatch = () => setMatch(window.matchMedia(query).matches);

    const mql = window.matchMedia(query);

    updateMatch();
    try {
      mql.addEventListener('change', updateMatch);
    } catch (e) {
      // Safari fix
      mql.addListener(updateMatch);
    }
    return () => {
      try {
        mql.removeEventListener('change', updateMatch);
      } catch (e) {
        // Safari fix
        mql.removeListener(updateMatch);
      }
    };
  }, [query]);

  return match;
};

export const useOutsideClick = (ref, callback, excludedClassName = null) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      if (
        !excludedClassName ||
        (typeof e.target.className === 'string' &&
          !e.target.className?.includes(excludedClassName))
      ) {
        callback();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export function useDebounce(callback, delay) {
  const callbackfunc = useRef(debounce(callback, delay)).current;

  return [callbackfunc];
}

export function useCheckRole(roles) {
  function isInRole(role) {
    return roles && roles.indexOf(role) > -1;
  }

  function isInAnyRole(roleArray) {
    return roleArray.reduce((acc, val) => {
      if (!acc && roles && roles.indexOf(val) > -1) return true;
      return acc;
    }, false);
  }

  return {
    isInRole,
    isInAnyRole,
  };
}

export function useHookWithRefCallback(callback) {
  const ref = useRef(null);
  const setRef = useCallback(node => {
    if (ref.current) {
      // Make sure to cleanup any events/references added to the last instance
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      callback(node);
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
}

export function useVideoAutoplay({ threshold = 0.8 } = {}) {
  let cantPlay = false;
  function checkScroll() {
    const videos = document.getElementsByTagName('video');

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];

      const x = video.offsetLeft;
      const y = video.offsetTop;
      const w = video.offsetWidth;
      const h = video.offsetHeight;
      const r = x + w; // right
      const b = y + h; // bottom

      const visibleX = Math.max(
        0,
        Math.min(
          w,
          window.pageXOffset + window.innerWidth - x,
          r - window.pageXOffset,
        ),
      );
      const visibleY = Math.max(
        0,
        Math.min(
          h,
          window.pageYOffset + window.innerHeight - y,
          b - window.pageYOffset,
        ),
      );

      const visible = (visibleX * visibleY) / (w * h);
      if (visible > threshold) {
        video
          .play()
          .then(() => (cantPlay = false))
          .catch(error => {
            Sentry.captureException(error);
            cantPlay = true; /* cant play video, do nothing */
          });
      } else if (!cantPlay) {
        video.pause();
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScroll, false);
    window.addEventListener('resize', checkScroll, false);
    return () => {
      window.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);
}

export function usePdfDownload({ language, category }) {
  const [pdfs, setPdfs] = useState([]);

  const { data: pdfData, isLoading } = fetch.pdfs.withSWR({
    language,
    query: {
      category_id: category?.split('_')?.[1],
    }
  })

  useEffect(() => {
    if (!isBlank(pdfData))
      setPdfs(pdfData)
  }, [pdfData, category]);

  return isLoading ? [] : pdfs;
}

export function useObjectLocalStorage(key, id, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once

  const [storedValue, setStoredValue] = useState(() => {
    if (!process.browser) return initialValue;

    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      if (item) {
        return JSON.parse(item)[id] ?? initialValue;
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      const item = window.localStorage.getItem(key);
      let itemObject = {};
      if (item) {
        itemObject = JSON.parse(item);
      }
      itemObject[id] = valueToStore;
      window.localStorage.setItem(key, JSON.stringify(itemObject));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
/* ---------------- DOWNLOADS ---------------- */
const DOWNLOADS_LAST_VISIT_KEY = 'downloads_last_visit';
export function convertFilesToObject(buttons) {
  if (!buttons || typeof buttons.forEach === undefined) return null;

  const buttonsObject = {};
  buttons.forEach(button => {
    buttonsObject[button.platform] = {
      fileUrl: button.file_url,
      fileSize: button.file_size,
    };
  });
  return buttonsObject;
}
function getLastVisit() {
  if (process.browser) {
    const item = window.localStorage.getItem(DOWNLOADS_LAST_VISIT_KEY);
    return item ? moment(JSON.parse(item)) : undefined;
  }
}
export function isNew(rawDate) {
  const publishedDate = moment(rawDate);
  const lastVisit = getLastVisit();
  if (lastVisit) {
    return publishedDate.valueOf() - lastVisit.valueOf() > 0;
  }
  return compareDates(publishedDate, moment()) <= 14;
}

export const useDownloadFiles = (tag, locale) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  async function fetchDownloads() {
    const downloadItems = await request(
      `v1/prusa3d_downloads?orderby=created&order=asc&lng=${locale}&tag=${tag}`,
    );
    const result = [];
    downloadItems?.data?.forEach(item => {
      let count = 0;
      if (result[item.meta.type.value]) {
        count = result[item.meta.type.value].count;
      }
      result[item.meta.type.value] = {
        title: item.title,
        content: item.content,
        releaseDate: item.meta.release_date,
        releaseLogUrl: item.meta.release_log_url,
        files: convertFilesToObject(item.meta.files),
        type: item.meta.type,
        count: count + 1,
        isNew: isNew(item.meta.release_date),
      };
    });
    setData(result);
    setLoading(false);
  }
  useEffect(() => {
    if (tag) fetchDownloads();
  }, [tag, locale]);

  return [data, loading];
};
