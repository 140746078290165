import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.header`
  width: 100%;
  border-bottom: 1px solid #ddd;
  text-align: center;
`;

const SearchBar = styled.aside`
  margin-bottom: 1em;
`;

const Input = styled.input`
  padding: 0.7em 1em;
  border: 1px solid #ddd;
  line-height: 2em;
  height: 2em;
  border-radius: 2em;
  outline: none;
  &:focus {
    border-color: #ed6b21;
  }
`;

function Header({ withSearch = false }) {
  return (
    <Wrapper>
      <h1>KNOWLEDGE BASE</h1>
      {withSearch && (
        <SearchBar>
          <Input type="text" placeholder="Search your problem" />
        </SearchBar>
      )}
    </Wrapper>
  );
}

Header.propTypes = {
  withSearch: PropTypes.bool,
};

export default Header;
