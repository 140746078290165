/**
 * @file User menu - user and admin control items
 * @todo Del cache with grace
 */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { GlobalContext, DispatchContext } from '../GlobalProvider/';
import theme from '../../utils/constants/theme';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';

import messages from './messages';
import IntlText from '../IntlText';
import { endpoints } from '../../../data/restApi/endpoints';
import Button from '../AppHeader/Button';
import { useLocalStorage } from '../../utils/hooks';
import { ListItem } from '../layout';
import { ButtonBigRealA } from '../layout/Button';
import Avatar from './Avatar';
import Menu from './Menu';
import Loader from '../Loader';

const LinkA = styled.a`
  transition: color 500ms;
  color: #333;
  font-weight: bold;
  ${props => props.margin && 'margin-left: 2em;'}

  &:hover {
    color: #fa6831;
  }
`;

const UserMenuButton = styled(Button)`
  padding: 0.2em 1em 0.1em 1em;
  color: ${({ isPreview }) => (isPreview ? 'white' : 'black')};
  background: ${({ isPreview }) =>
    isPreview ? theme.colors.main : 'transparent'};
  margin-right: ${({ mobile }) => (mobile ? '0.5em' : '1em')};
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  @media screen and (max-width: 500px) {
    font-size: 80%;
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  size: 0.5em;
  white-space: nowrap;
  align-items: center;
  gap: 0.5em;
`;

const LoginButton = styled(ButtonBigRealA)`
  position: relative;
  top: 2rem;
  padding: 8px 10px;
`;

const UserMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LogoutLink = styled.a`
  cursor: pointer;
`;

function isUrlValid(input) {
  let valid;
  let url;

  try {
    url = new URL(input);
    valid = true;
  } catch {
    valid = false;
  }

  if (valid && url.protocol === 'https:') return true;

  return false;
}

const ClearCacheBtn = ({ mobile }) => {
  const [isWorking, setWorking] = useState(false);
  const router = useRouter();

  const style = mobile ? {} : { marginRight: '' };
  const fetchOptions = {
    cache: 'no-store',
    redirect: 'follow',
    referrer: 'no-referrer',
  };

  const clearCache = useCallback(() => {
    setWorking(true);

    const locale = router.locale === 'en' ? '' : `/${router.locale}`;
    const pathToRevalidate = `${locale}${router.asPath}`;

    Promise.all([
      fetch('/api/v1/cache?clear=true', fetchOptions).then({
        memcache: 'invalidated',
      }),
      // fetch(
      //   `/revalidateFrontendPages?token=yi43HoaNty6aTMMygo6cCzFnzeFM7SB8&path=${pathToRevalidate}`,
      //   fetchOptions,
      // ).then(resp => resp.json()),
    ])
      .then(res => {
        setWorking(false);
        alert('Cache cleared. Page is going to be reloaded.');
        location.reload();
      })
      .catch(error => {
        setWorking(false);
        Sentry.captureException(error);
        console.error(error);
      });
  }, [router.asPath, router.locale]);

  if (isWorking)
    return (
      <LoaderWrapper>
        <Loader size={18} />
        <div>Clearing cache...</div>
      </LoaderWrapper>
    );

  return (
    <UserMenuButton onClick={() => clearCache()} mobile>
      Clear Cache
    </UserMenuButton>
  );
};

const PreviewModeSwitch = () => {
  // const { isPreview } = useContext(GlobalContext);
  const router = useRouter();
  const entityAlias =
    router.pathname === '/' || router.asPath === '/preview/home/page'
      ? 'home/page'
      : '';
  const allowedPaths = /^(\/tag\/.+|\/category\/.+|\/guide\/.+|\/article\/.+|\/)$/;

  if (!process.browser) return null;

  if (router.pathname.includes('/preview/'))
    return (
      <UserMenuButton
        onClick={() =>
          router.push(router.asPath.replace(`preview/${entityAlias}`, ''))
        }
        isPreview={true}
      >
        Return from preview
      </UserMenuButton>
    );

  if (!allowedPaths.test(window.location.pathname)) return null;

  return (
    <UserMenuButton
      onClick={() =>
        router.push(
          `/preview${entityAlias ? `/${entityAlias}` : ''}${router.asPath}`,
        )
      }
      isPreview={false}
    >
      Show in preview mode
    </UserMenuButton>
  );
};

function UserMenu({ mobile = false, canEdit, isAdmin, canViewPrivate }) {
  const { currentUser } = useContext(GlobalContext);
  const { dispatch } = useContext(DispatchContext);
  // const userData = isBlank(currentUser) ? sessionStorage.getItem('prusuki_current_user') : currentUser;
  const deleteSession = useCallback(() => {
    if (!process.browser) return null;
    sessionStorage.removeItem('prusuki_preview_mode');
    sessionStorage.removeItem('jwt_token'); // ### added
    sessionStorage.removeItem('prusuki_current_user');
  }, []);
  const [, setLoc] = useLocalStorage('pkb_redirect_url', null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const router = useRouter();
  const onLogout = useCallback(() => {
    fetch(endpoints.prusuki.logout)
      .then(response => response.text())
      .then(data => {
        deleteSession();
        if (isUrlValid(data) && process.browser) window.location.assign(data);
      })
      .catch(error => {
        console.error(error);
        Sentry.captureException(error);
      });
  });

  const toggleMenu = useCallback(() => setUserMenuOpen(!userMenuOpen));

  function setCurrentLocation() {
    setLoc(`${router.asPath}`);
  }

  useEffect(() => {
    if (router.query?.preview === 'true')
      dispatch({ type: 'setPreview', payload: 'true' });
  }, [router.query?.preview]);

  if (currentUser?.login) {
    return (
      <>
        <Head>{isAdmin && <link rel="prefetch" href="/admin" />}</Head>
        <UserMenuWrapper>
          {isAdmin && <ClearCacheBtn />}
          {canViewPrivate && (
            <>
              <PreviewModeSwitch />
              {/* <UserMenuButton
            onClick={() =>
              dispatch({ type: 'setPreview', payload: !isPreview })
            }
            isPreview={isPreview}
          >
            {isPreview ? 'Dis' : 'En'}able Preview
          </UserMenuButton> */}
              {canEdit && (
                <a href="/admin/" style={{ marginRight: '1rem' }}>
                  Admin
                </a>
              )}
            </>
          )}
          <div
            onMouseOver={toggleMenu}
            onFocus={toggleMenu}
            onMouseOut={toggleMenu}
            onBlur={toggleMenu}
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              paddingLeft: '0.5rem',
            }}
          >
            <Avatar
              src={currentUser.avatar}
              width={30}
              height={30}
              alt={currentUser.username}
            />
            <Menu open={userMenuOpen}>
              <ListItem>
                <strong>{currentUser.username}</strong>
              </ListItem>
              <ListItem>
                <a
                  href={`${currentUser.auth_url}profile-edit?next=${process.env.NEXT_PUBLIC_ORIGIN
                    }${router.asPath}`}
                >
                  <IntlText index={messages.myProfile} />
                </a>
              </ListItem>
              <ListItem>
                {/* a changed to button ### */}
                <LogoutLink onClick={onLogout}>
                  <IntlText index={messages.logout} />
                </LogoutLink>
              </ListItem>
            </Menu>
          </div>
        </UserMenuWrapper>
      </>
    );
  }
  // if (!currentUser?.login) {
  // if (mobile) {
  //   return (
  //     <LoginButton
  //       href={process.env.NEXT_PUBLIC_LOGIN_URL}
  //       onClick={setCurrentLocation}
  //       margin={!mobile}
  //     >
  //       <IntlText index={messages.login} />
  //     </LoginButton>
  //   );
  // }

  return (
    <LinkA
      href={process.env.NEXT_PUBLIC_LOGIN_URL}
      onClick={setCurrentLocation}
      margin={!mobile}
    >
      <IntlText index={messages.login} />
    </LinkA>
  );
}

UserMenu.propTypes = {
  isAdmin: PropTypes.bool,
  canEdit: PropTypes.bool,
  canViewPrivate: PropTypes.bool,
  mobile: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default UserMenu;
