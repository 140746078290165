/*
 * ForumWidget Messages
 *
 * This contains all the text for the ForumWidget container.
 */

export const scope = 'app.containers.ForumWidget';

const messages = {
	topPosts: `${scope}.topPosts`,
	visitForum: `${scope}.visitForum`,
	updated: `${scope}.updated`,
	by: `${scope}.by`,
};

export default messages;
