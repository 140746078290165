import styled from 'styled-components';
import theme from "../../utils/constants/theme";

export const Title = styled.h3`
  color: #333;
  text-shadow: 1px 1px 1px #fff;
  transition: color 500ms;
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
`;

export const Card = styled.article`
  position: relative;
  width: calc((100% - 6em) / 4);
  margin-right: 2em;
  &:nth-child(4n) {
    margin-right: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${props => props.theme.colors.lightGrey};
  background-color: white;
  overflow: hidden;
  margin-bottom: 2em;

  &:hover {
    ${Title} {
      color: #ed6b21;
    }
  }

  @media screen and (max-width: 48em) {
    width: calc((100% - 1em) / 2);
    margin-right: 1em;
    margin-bottom: 1em;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
`;
