// import 'whatwg-fetch';
import * as Sentry from '@sentry/nextjs';
import persistentState, { PERSISTENT_STATE_KEYS } from './persistentState';

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 302) {
    if (response.status === 204) return '';
    return response
      .json()
      .catch(error => {
        Sentry.captureException(error);
        // console.error(`'${err}' happened, but no big deal!`);
      });
  }
  // First catch any errors (for example cannot parse JSON), then get error message
  return response
    .json()
    .catch(error => {
      Sentry.captureException(error);
      throw new Error(`${response.status} ${response.statusText}`);
    })
    .then(responseJson => {
      const error = new Error(responseJson.message);

      // We want to pass parsed body response into failed Error
      // @-> For later grabbing error messages in components/containers
      error.response = responseJson;

      throw error;
    });
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(
  url,
  { params, headers = {}, method = 'get', body } = {},
  excludeAuth = false,
) {
  const searchParams = new URLSearchParams();
  if (params) {
    Object.keys(params).forEach(key => {
      if (key && params[key] && typeof params[key] === 'object') {
        Object.keys(params[key]).forEach(arrayKey => {
          searchParams.append(`${key}[${arrayKey}]`, params[key][arrayKey]);
        });
      } else if (key && params[key]) {
        searchParams.append(key, params[key]);
      }
    });
  }
  let paramsString = searchParams.toString();
  if (paramsString.length > 0) {
    paramsString = `?${paramsString}`;
  }
  let finalUrl = '';
  if (url.includes('http') || url.includes('/next/')) {
    finalUrl = `${url}${paramsString}`;
  } else {
    finalUrl = `/api/${url}${paramsString}`;
  }
  const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (!excludeAuth) {
    const token = persistentState.cookie.get({
      key: PERSISTENT_STATE_KEYS.jwt
    });

    if (token) {
      baseHeaders['JWT-Authorization'] = `Bearer ${token}`;
    }
  }

  // Not needed for now
  /* if (headers['Content-Type'] === null) {
    delete headers['Content-Type'];
    delete baseHeaders['Content-Type'];
  } */

  const init = {
    redirect: 'follow',
    referrer: 'no-referrer',
    cache: 'no-cache',
    // mode: 'cors',
    method,
    headers: Object.assign(baseHeaders, headers),
  };

  if (!excludeAuth) {
    init.credentials = 'include';
  }

  if (method !== 'get' && method !== 'head') {
    init.body = body;
  }

  return fetch(finalUrl, init).then(checkStatus);
}
