import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function ScrollToTop() {
  const router = useRouter();
  const { pathname } = router;

  useEffect(() => {
    function hashChangeComplete() {
      if (!window.location.hash) {
        window.scrollTo(0, 0);
      }
    }

    router.events.on('hashChangeComplete', hashChangeComplete);

    return () => {
      router.events.off('hashChangeComplete', hashChangeComplete);
    };
  }, [pathname]);

  return null;
}
