/**
 * @file HomePage messages
 * @description this module contain text for HomePage component.
 */
export const scope = 'app.containers.HomePage';

const messages = {
    description: `${scope}.description`,
    header: `${scope}.header`,
    popularCategories: `${scope}.popularCategories`,
    faqs: `${scope}.faqs`,
    allCategories: `${scope}.allCategories`,
    allFaqs: `${scope}.allFaqs`,
    supportByPrinter: `${scope}.supportByPrinter`,
    slicer: `${scope}.slicer`,
    readMore: `${scope}.readMore`,
    showAllPrinters: `${scope}.showAllPrinters`,
    filamentGuide: `${scope}.filamentGuide`,
    downloads: `${scope}.downloads`,
  connect: `${scope}.connect`,
  };
  
export default messages;