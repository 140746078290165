import React from 'react';
import _, { isEmpty } from 'lodash';
import parse from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';

export const isBlank = input => {
  if (Array.isArray(input)) return !input?.[0];
  if (typeof input === 'object') return isEmpty(input);
  else return !input;
};

export const checkForUnallowedCharacters = requestUrl => {
  const unallowedCharacters = /<|>|\(|\)|\[|\]|\*|\{|\}|\|\/|\$|;|:|'|"/; // |@
  const url = new URL(requestUrl);
  const searchParams = url.searchParams;
  const noncheckedKeys = ['token', 'search', 'password'];

  let clean = true;

  for (const [key, value] of searchParams.entries()) {
    if (noncheckedKeys.includes(key)) continue;

    if (unallowedCharacters.test(key) || unallowedCharacters.test(value))
      clean = false;
  }

  if (!clean) {
    process?.browser ||
      console.log('Invalid characters in requestUrl: ', requestUrl);
    return false; // throw new Error('Invalid characters in requestUrl: ' + requestUrl);
  }

  return true;
};

const baseTagSubset = [
  'address',
  'article',
  'aside',
  'footer',
  'header',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'hgroup',
  'main',
  'nav',
  'section',
  'blockquote',
  'dd',
  'div',
  'dl',
  'dt',
  'figcaption',
  'figure',
  'hr',
  'li',
  'main',
  'ol',
  'p',
  'pre',
  'ul',
  'a',
  'abbr',
  'b',
  'bdi',
  'bdo',
  'br',
  'cite',
  'code',
  'data',
  'dfn',
  'em',
  'i',
  'kbd',
  'mark',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  'ruby',
  's',
  'samp',
  'small',
  'span',
  'strong',
  'sub',
  'sup',
  'time',
  'u',
  'var',
  'wbr',
  'caption',
  'col',
  'colgroup',
  'table',
  'tbody',
  'td',
  'tfoot',
  'th',
  'thead',
  'tr',
  'img',
  'video',
];

/**
 * @todo allowVulnerableTags - to check content if its possible to turn off and under what conditions
 */
export function parseSanitizedHTML(HtmlString, strict = false) {
  const strictAllowedTags = strict === 'no-html' ? [] : baseTagSubset;
  const sanitizeOptions = {
    allowedTags: strict ? strictAllowedTags : false,
    allowedAttributes: strict === 'no-html' ? {} : false,
    allowVulnerableTags: true, // needs to be evaluated
  }

  const sanitizedHtml = sanitizeHtml(HtmlString, sanitizeOptions);

  return parse(sanitizedHtml);
}

export const adjustSrc = src => {
  if (!process.env.TESTING_BUILD) return src;
  // const replaceRegexp = /(helpdev|devprusuki)/g;
  // return src?.replace(replaceRegexp, 'help') || src;
  return src;
};

export const dataByCat = data =>
  data &&
  data.reduce(
    (acc, val) => {
      if (val.categories && val.categories.length) {
        if (!val.categories || val.categories.length === 0) {
          acc.uncategorized.push(val);
        } else {
          if (!acc[val.categories[0]]) {
            acc[val.categories[0]] = [];
          }
          acc[val.categories[0]].push(val);
        }
      }
      return acc;
    },
    { uncategorized: [] },
  );

export const omitProps = ({ arrayOfObjects, propsToOmit }) => {
  if (!arrayOfObjects.length) return null;
  const reducedObject = arrayOfObjects.map(object => {
    if (object?.childrens) {
      object.childrens = object.childrens.map(child =>
        _.omit(child, propsToOmit),
      );
    }

    return _.omit(object, propsToOmit);
  });

  return reducedObject || null;
};

export const formatDateForLocale = (locale, dateString) => {
  const dateFormats = {
    en: 'MMMM DD, YYYY',
    cs: 'DD.MM.YYYY',
    de: 'DD.MM.YYYY',
    it: 'DD.MM.YYYY',
    es: 'DD.MM.YYYY',
    pl: 'DD.MM.YYYY',
    fr: 'DD/MM/YYYY',
    jp: 'MM/DD/YYYY',
  };

  return moment(dateString).format(dateFormats[locale]);
};
// Facilitat optimisation of cache and ISR revalidation at BE.
export function getReferer({ path, locale }) {
  const localePath = locale === 'en' ? '' : `/${locale}`;
  return `${localePath}${path}`;
}

export function getCategoriesBySlug(data) {
  let categoriesBySlug = {};

  const mapCategoriesToSlags = categories => {
    categories.map(category => {
      if (category?.childrens?.length)
        mapCategoriesToSlags(category?.childrens);

      return (categoriesBySlug[category.slug] = category);
    });
  };

  mapCategoriesToSlags(data);

  return categoriesBySlug;
}

export const convertStylesStringToObject = stringStyles =>
  typeof stringStyles === 'string'
    ? stringStyles.split(';').reduce((acc, style) => {
      const colonPosition = style.indexOf(':');

      if (colonPosition === -1) {
        return acc;
      }

      const camelCaseProperty = style
        .substr(0, colonPosition)
        .trim()
        .replace(/^-ms-/, 'ms-')
        .replace(/-./g, c => c.substr(1).toUpperCase()),
        value = style.substr(colonPosition + 1).trim();

      return value ? { ...acc, [camelCaseProperty]: value } : acc;
    }, {})
    : {};

function unifyPairs(query) {
  let pairs;

  if (!query) return '';

  if (typeof query === 'object') pairs = Object.entries(query);
  else if (query instanceof Map) pairs = query;
  else if (query.isArray()) pairs = query;
  else return '';

  const unifiedPairs = pairs;

  return unifiedPairs;
}

function removeEmptyPairs(pairs) {
  const unifiedPairs = unifyPairs(pairs);

  const filteredPairs = unifiedPairs.filter(([, value]) => {
    return value ? true : false;
  });

  return filteredPairs;
}

export function prepareUrl({ baseUrl, query }) {
  const url = new URL(baseUrl);
  const fileteredPairs = removeEmptyPairs(query);
  const queryParams = new URLSearchParams(fileteredPairs);
  const baseUrlParams = new URLSearchParams(url.search);

  for (let [key, val] of baseUrlParams.entries()) {
    queryParams.set(key, val);
  }
  queryParams.sort();
  url.search = queryParams.toString();
  const finalUrl = url.toString();

  return finalUrl;
}

function getTimeFromNumber(num) {
  const hours = Math.floor(num / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (num % 60).toString().padStart(2, '0');
  return [hours, minutes];
}

export function formatTime(time) {
  if (Array.isArray(time)) {
    const [hours, minutes] = getTimeFromNumber(time[0]);
    const [hoursEnd, minutesEnd] = getTimeFromNumber(time[1]);
    return `${hours}:${minutes}:00 - ${hoursEnd}:${minutesEnd}:00`;
  }
  const [hours, minutes] = getTimeFromNumber(time);
  return `${hours}:${minutes}:00`;
}

export function checkForPreview(isAdmin, location) {
  return isAdmin && location.search.startsWith('?preview=true');
}

export function urlBuilder({ language, item, params = null, preview = false }) {
  let url = '';
  switch (item.type) {
    case 'manuals':
    case 'manual':
    case 'category':
      url = `/category/${item.slug}`;
      break;
    case 'helps':
    case 'help':
      url = `/article/${item.slug}`;
      break;
    case 'guides':
    case 'guide':
      url = `/guide/${item.slug}`;
      break;
    case 'tags':
    case 'post_tag':
    case 'tag':
      url = `/tag/${item.slug}`;
      break;
    case 'downloads':
    case 'download':
      url = `/downloads/${item.slug}`;
      break;
    case 'tooltips':
    case 'tooltip':
      url = `/glossary/${item.slug}`;
      break;
    case 'relation':
    case 'relations':
      url = `/relation/${item.slug}`;
      break;
    case 'keyword':
    case 'keywords':
      url = `/keyword/${item.slug}`;
      break;
    case 'user':
      url = `/user/${item.id}`;
      break;
    default:
      url = `/404`;
      break;
  }
  if (params) {
    url += `?${params}`;
  }

  if (
    preview ||
    (process.browser && window.location.pathname.includes('preview/'))
  ) {
    url = `/preview/${url}`;
  }

  return url;
}

export function findCatBySlug(categories, slug) {
  let found = false;
  if (!categories) return false;
  categories.forEach(cat => {
    if (cat.slug === slug) {
      found = cat;
    } else if (cat.childrens && cat.childrens.length > 0) {
      const f = findCatBySlug(cat.childrens, slug);
      if (f) found = f;
    }
  });
  return found;
}

export function getCatChain(categories, slug) {
  return getCatChainBySlug(categories, slug);
}

export function getCatChainBySlug(categories, slug) {
  let found = [];
  if (!categories) return [];
  categories.forEach(cat => {
    if (cat.slug === slug) {
      found.push(cat);
    } else if (cat.childrens && cat.childrens.length > 0) {
      const f = getCatChainBySlug(cat.childrens, slug);
      if (f.length > 0) {
        f.push(cat);
        found = f;
      }
    }
  });
  return found;
}

export const scrollWithOffset = (el, offset, smooth = true, pure = false) => {
  if (el) {
    const elementPosition =
      (pure ? el.getBoundingClientRect().top : el.offsetTop) - offset;
    window.scrollTo({
      top: elementPosition,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }
};

export function slugify(string) {
  const a =
    'àáäâãåăæąçćčđďèéěėëêęğǵḧìíïîįłḿǹńňñòóöôœøṕŕřßşśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;';
  const b =
    'aaaaaaaaacccddeeeeeeegghiiiiilmnnnnooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return string
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

export function reorder(data, idx, idx2) {
  if (idx < idx2) {
    data.splice(idx2 + 1, 0, data[idx]);
    data.splice(idx, 1);
  } else {
    data.splice(idx2, 0, data[idx]);
    data.splice(idx + 1, 1);
  }
  return data.map((item, i) => ({ ...item, order: i }));
}

export function parseQuery(queryString) {
  const query = {};
  const pairs = (queryString[0] === '?'
    ? queryString.substr(1)
    : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export function serialize(mixedValue) {
  //  discuss at: https://locutus.io/php/serialize/
  // original by: Arpad Ray (mailto:arpad@php.net)
  // improved by: Dino
  // improved by: Le Torbi (https://www.letorbi.de/)
  // improved by: Kevin van Zonneveld (https://kvz.io/)
  // bugfixed by: Andrej Pavlovic
  // bugfixed by: Garagoth
  // bugfixed by: Russell Walker (https://www.nbill.co.uk/)
  // bugfixed by: Jamie Beck (https://www.terabit.ca/)
  // bugfixed by: Kevin van Zonneveld (https://kvz.io/)
  // bugfixed by: Ben (https://benblume.co.uk/)
  // bugfixed by: Codestar (https://codestarlive.com/)
  // bugfixed by: idjem (https://github.com/idjem)
  //    input by: DtTvB (https://dt.in.th/2008-09-16.string-length-in-bytes.html)
  //    input by: Martin (https://www.erlenwiese.de/)
  //      note 1: We feel the main purpose of this function should be to ease
  //      note 1: the transport of data between php & js
  //      note 1: Aiming for PHP-compatibility, we have to translate objects to arrays
  //   example 1: serialize(['Kevin', 'van', 'Zonneveld'])
  //   returns 1: 'a:3:{i:0;s:5:"Kevin";i:1;s:3:"van";i:2;s:9:"Zonneveld";}'
  //   example 2: serialize({firstName: 'Kevin', midName: 'van'})
  //   returns 2: 'a:2:{s:9:"firstName";s:5:"Kevin";s:7:"midName";s:3:"van";}'
  //   example 3: serialize( {'ü': 'ü', '四': '四', '𠜎': '𠜎'})
  //   returns 3: 'a:3:{s:2:"ü";s:2:"ü";s:3:"四";s:3:"四";s:4:"𠜎";s:4:"𠜎";}'

  let val;
  let key;
  let okey;
  let ktype = '';
  let vals = '';
  let count = 0;

  const _utf8Size = function (str) {
    return ~-encodeURI(str).split(/%..|./).length;
  };

  const _getType = function (inp) {
    let match;
    let key;
    let cons;
    let types;
    let type = typeof inp;

    if (type === 'object' && !inp) {
      return 'null';
    }

    if (type === 'object') {
      if (!inp.constructor) {
        return 'object';
      }
      cons = inp.constructor.toString();
      match = cons.match(/(\w+)\(/);
      if (match) {
        cons = match[1].toLowerCase();
      }
      types = ['boolean', 'number', 'string', 'array'];
      for (key in types) {
        if (cons === types[key]) {
          type = types[key];
          break;
        }
      }
    }
    return type;
  };

  const type = _getType(mixedValue);

  switch (type) {
    case 'function':
      val = '';
      break;
    case 'boolean':
      val = `b:${mixedValue ? '1' : '0'}`;
      break;
    case 'number':
      val = `${Math.round(mixedValue) === mixedValue ? 'i' : 'd'
        }:${mixedValue}`;
      break;
    case 'string':
      val = `s:${_utf8Size(mixedValue)}:"${mixedValue}"`;
      break;
    case 'array':
    case 'object':
      val = 'a';
      /*
      if (type === 'object') {
        var objname = mixedValue.constructor.toString().match(/(\w+)\(\)/);
        if (objname === undefined) {
          return;
        }
        objname[1] = serialize(objname[1]);
        val = 'O' + objname[1].substring(1, objname[1].length - 1);
      }
      */

      for (key in mixedValue) {
        if (mixedValue.hasOwnProperty(key)) {
          ktype = _getType(mixedValue[key]);
          if (ktype === 'function') {
            continue;
          }

          okey = key.match(/^[0-9]+$/) ? parseInt(key, 10) : key;
          vals += serialize(okey) + serialize(mixedValue[key]);
          count++;
        }
      }
      val += `:${count}:{${vals}}`;
      break;
    case 'undefined':
    default:
      // Fall-through
      // if the JS object has a property which contains a null value,
      // the string cannot be unserialized by PHP
      val = 'N';
      break;
  }
  if (type !== 'object' && type !== 'array') {
    val += ';';
  }

  return val;
}

/*
 * "22,54" => [22,54]
 * "18" => 18
 */
export function parseTimeRange(time) {
  return time.indexOf(',') > 0
    ? time.split(',').map(i => parseInt(i, 0))
    : parseInt(time, 0) || 0;
}

export function flattenCategories(categories) {
  let result = [];
  categories.forEach(cat => {
    result.push(cat);
    if (cat.childrens && cat.childrens.length) {
      result = result.concat(flattenCategories(cat.childrens));
    }
  });
  return result;
}

export function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

/**
 * Throttle execution of a function. Especially useful for rate limiting
 * execution of handlers on events like resize and scroll.
 *
 * @param  {Number}    delay          A zero-or-greater delay in milliseconds. For event callbacks, values around 100 or 250 (or even higher) are most useful.
 * @param  {Boolean}   [noTrailing]   Optional, defaults to false. If noTrailing is true, callback will only execute every `delay` milliseconds while the
 *                                    throttled-function is being called. If noTrailing is false or unspecified, callback will be executed one final time
 *                                    after the last throttled-function call. (After the throttled-function has not been called for `delay` milliseconds,
 *                                    the internal counter is reset)
 * @param  {Function}  callback       A function to be executed after delay milliseconds. The `this` context and all arguments are passed through, as-is,
 *                                    to `callback` when the throttled-function is executed.
 * @param  {Boolean}   [debounceMode] If `debounceMode` is true (at begin), schedule `clear` to execute after `delay` ms. If `debounceMode` is false (at end),
 *                                    schedule `callback` to execute after `delay` ms.
 *
 * @return {Function}  A new, throttled, function.
 */
export function throttle(delay, noTrailing, callback, debounceMode) {
  /*
   * After wrapper has stopped being called, this timeout ensures that
   * `callback` is executed at the proper times in `throttle` and `end`
   * debounce modes.
   */
  let timeoutID;
  let cancelled = false;

  // Keep track of the last time `callback` was executed.
  let lastExec = 0;

  // Function to clear existing timeout
  function clearExistingTimeout() {
    if (timeoutID) {
      clearTimeout(timeoutID);
    }
  }

  // Function to cancel next exec
  function cancel() {
    clearExistingTimeout();
    cancelled = true;
  }

  // `noTrailing` defaults to falsy.
  if (typeof noTrailing !== 'boolean') {
    debounceMode = callback;
    callback = noTrailing;
    noTrailing = undefined;
  }

  /*
   * The `wrapper` function encapsulates all of the throttling / debouncing
   * functionality and when executed will limit the rate at which `callback`
   * is executed.
   */
  function wrapper() {
    const self = this;
    const elapsed = Date.now() - lastExec;
    const args = arguments;

    if (cancelled) {
      return;
    }

    // Execute `callback` and update the `lastExec` timestamp.
    function exec() {
      lastExec = Date.now();
      callback.apply(self, args);
    }

    /*
     * If `debounceMode` is true (at begin) this is used to clear the flag
     * to allow future `callback` executions.
     */
    function clear() {
      timeoutID = undefined;
    }

    if (debounceMode && !timeoutID) {
      /*
       * Since `wrapper` is being called for the first time and
       * `debounceMode` is true (at begin), execute `callback`.
       */
      exec();
    }

    clearExistingTimeout();

    if (debounceMode === undefined && elapsed > delay) {
      /*
       * In throttle mode, if `delay` time has been exceeded, execute
       * `callback`.
       */
      exec();
    } else if (noTrailing !== true) {
      /*
       * In trailing throttle mode, since `delay` time has not been
       * exceeded, schedule `callback` to execute `delay` ms after most
       * recent execution.
       *
       * If `debounceMode` is true (at begin), schedule `clear` to execute
       * after `delay` ms.
       *
       * If `debounceMode` is false (at end), schedule `callback` to
       * execute after `delay` ms.
       */
      timeoutID = setTimeout(
        debounceMode ? clear : exec,
        debounceMode === undefined ? delay - elapsed : delay,
      );
    }
  }

  wrapper.cancel = cancel;

  // Return the wrapper function.
  return wrapper;
}

export function printObject(obj, depth = 0) {
  if (obj === undefined || obj === null) return 'empty';
  if (typeof obj === typeof true) return obj ? 'Yes' : 'No';
  if (typeof obj !== 'object') return obj;

  return (
    <div style={{ paddingLeft: `${depth * 10}px` }}>
      {'{'}
      {Object.entries(obj).map(([key, val]) => (
        <div key={key} style={{ paddingLeft: `${depth * 10 + 5}px` }}>
          {key}: {printObject(val, depth + 1)}
        </div>
      ))}
      {'}'}
    </div>
  );
}

export function recursiveLookup(arr, x, start, end) {
  if (start > end) return false;

  const mid = Math.floor((start + end) / 2);

  if (arr[mid] === x) return true;

  if (arr[mid] > x) return recursiveLookup(arr, x, start, mid - 1);
  return recursiveLookup(arr, x, mid + 1, end);
}

export function insert(element, array) {
  array.splice(locationOf(element, array) + 1, 0, element);
  return array;
}

function locationOf(element, array, start, end) {
  const newStart = start || 0;
  const newEnd = end || array.length;
  const pivot = parseInt(newStart + (newEnd - newStart) / 2, 10);
  if (newEnd - newStart <= 1 || array[pivot] === element) return pivot;
  if (array[pivot] < element) {
    return locationOf(element, array, pivot, newEnd);
  }
  return locationOf(element, array, newStart, pivot);
}

export function isScrolledIntoView(element) {
  const docViewTop = window.scrollY;
  const docViewBottom = docViewTop + window.innerHeight;

  const elemTop = element?.offsetTop;
  const elemBottom = elemTop + element?.offsetHeight;

  return elemBottom <= docViewBottom;
}

export function compareDates(firstDate, secondDate) {
  const difference = Math.abs(secondDate.valueOf() - firstDate.valueOf());
  return difference / (1000 * 3600 * 24);
}

export function detectOs(defaultOs) {
  let osName = defaultOs;

  if (process.browser) {
    if (navigator.appVersion.indexOf('Win') !== -1) osName = 'win';
    if (navigator.appVersion.indexOf('Mac') !== -1) osName = 'mac';
    if (navigator.appVersion.indexOf('Linux') !== -1) osName = 'linux';
  }
  return osName;
}

export const getEncodedUrlString = str => {
  const encodedUrl = encodeURI(str);
  return encodedUrl.toLowerCase();
};

export const checkIfSlugDiffers = (entities, urlSlug) => {
  if (isBlank(entities)) return null;

  const actualSlug = entities?.find(
    entity => entity.id == urlSlug?.match(/^.+_([0-9]+)$/)?.[1],
  )?.slug;

  if (!!actualSlug && urlSlug !== actualSlug) {
    return actualSlug;
  }

  return false;
};

export const isUrlEncoded = str => {
  return decodeURI(str) !== str;
};

export const redirectIfSlugDontMatchLocale = ({
  slugFromData,
  slugFromProps,
  locale,
  entityPath,
}) => {
  const shouldRedirect =
    typeof slugFromData === 'string' &&
    slugFromProps !== slugFromData &&
    !slugFromData.includes('%');

  const redirect = {
    redirect: {
      destination: `/${locale}${entityPath}${slugFromData}`,
    },
  };

  return {
    shouldRedirect,
    redirect,
  };
};

export const getWordpressCookie = cookies => {
  if (!cookies) return ''; // Empty string will remove the param from API's GET query.

  const regex = /(wordpress_logged_in_[a-z0-9]+=[^;]+);?/;
  const wordpressCookie = cookies.match(regex);

  if (!wordpressCookie) return [];
  return wordpressCookie[1];
};

export function getPrusalatorUrl(key, languageId) {
  return `${process.env.NEXT_PUBLIC_PRUSALATOR_URL}project/${process.env.NEXT_PUBLIC_PRUSALATOR_PROJECT_ID}/locale/${languageId}/key/without?searchKey=${key}&openKey=1`;
}