import styled from 'styled-components';
import theme from '../../utils/constants/theme';

export const Tabs = styled.nav`
  border-bottom: 1px solid #ddd;
`;
export const Tab = styled.a`
  border: 1px solid #ddd;
  border-bottom: none;
  padding: 1em;
  cursor: pointer;
  margin: 0 5px;
  background-color: #ddd;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  &.active,
  &:hover {
    color: ${props => props.theme.colors.main};
    background-color: #ffffff;
    border-bottom: 1px solid #fff;
  }

  &.activeTab {
    transform: scale(1.1);
    background: ${props => props.theme.colors.smoggishGrey} !important;
    border-bottom: 1px solid ${props => props.theme.colors.smoggishGrey};
  }
`;
