import styled from 'styled-components';
import Link from 'next/link';
import { themeColor } from '../../utils/themeHelpers';

export const MenuItem = styled.a`
  position: relative;
  color: #333;
  display: inline-block;
  padding: 0.2em;
  cursor: pointer;
  z-index: 410;
  &:hover {
    color: ${themeColor('main')};
  }
  ${props => props.selected && 'font-weight: 500;'}
`;

export const Menu = styled.div`
  text-align: left;
  margin-top: 12px;
  position: absolute;
  z-index: 400;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0 1em;
  overflow-y: auto;
  height: calc(100vh - (${props => props.maxHeight}px + 48px) + 1.2em);
`;

export const Section = styled.div`
  border-bottom: 1px solid ${themeColor('lightGrey')};
  color: #b3b3b3;
  h4 {
    font-weight: normal;
  }
`;

export default Menu;
