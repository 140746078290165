/**
 * @file FeaturedList
 */
import PropTypes from 'prop-types';

import { List } from '../layout';
import ListItem from './ListItem';
import Loader from '../Loader';

function FeaturedList({
  items = [],
  tags,
  withThumbnails = true,
  withIcon = false,
  large = false,
  categories,
  isLoading = false,
  setLoading,
  categorySlug,
  page = null,
}) {
  if (isLoading) return <Loader />;

  if (!items || !tags) return null;

  return (
    <List>
      {items.map(item => (
        <ListItem
          key={item.id}
          item={item}
          withThumbnails={withThumbnails}
          tags={tags}
          withIcon={withIcon}
          categories={categories}
          large={large}
        />
      ))}
    </List>
  );
}

FeaturedList.propTypes = {
  items: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  withThumbnails: PropTypes.bool,
  withIcon: PropTypes.bool,
  large: PropTypes.bool,
};

export default FeaturedList;
