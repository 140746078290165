import styled from 'styled-components';

export default styled.div`
  @media print {
    display: block;
  }
  @media screen {
    display: none;
  }
`;
