import https from 'https';
import { getClientsideUrl } from './fetchApi';
// import * as Sentry from '@sentry/nextjs';
import { log, LOG_TYPE } from '../../app/utils/logger';
import { debugFetchResponse } from '../../app/utils/debugger';
import { checkForUnallowedCharacters } from '../../app/utils/helpers';

import persistentState, { PERSISTENT_STATE_KEYS } from '../../app/utils/persistentState';

const getAgentObject = () => {
  // temp: Skip SSL validation for server requests ###
  const agent = process.browser
    ? null
    : new https.Agent({
      rejectUnauthorized: false,
    });

  return agent;
};

const addAuthHeader = (headers, jwt) => {
  // if (
  //   url.pathname.includes('/user') ||
  //   searchParams.get('preview') ||
  //   searchParams.get('admin')
  // ) {
  if (process.browser && persistentState.cookie.get({ key: PERSISTENT_STATE_KEYS.jwt })) {
    const bearer = `Bearer ${persistentState.cookie.get({ key: PERSISTENT_STATE_KEYS.jwt })}`;
    headers.set('JWT-Authorization', bearer);
  } else {
    if (jwt) headers.set('JWT-Authorization', `Bearer ${jwt}`);
  }
  // }
};

//  Prepare headers
const prepareHeaders = (requestUrl, cookie, referer, jwt) => {
  const defaultHeaders = [
    // ['Accept', 'application/json'],
    // ['Content-Type', 'application/json'],
    ['prusuki-fe-request-datetime', Date.now().toString()],
  ];

  const headers = new Headers(defaultHeaders);

  // Auth header for preview mode and login.
  // const url = new URL(requestUrl);
  // const searchParams = url.searchParams;

  addAuthHeader(headers, jwt);

  if (referer) {
    headers.set('Referer', encodeURI(referer));
  }

  if (cookie) {
    headers.set('Cookie', cookie);
  }

  return headers;
};

const prepareData = (json, doExtractData, requestUrl, fetchDuration) => {
  let data = null;

  // Extract data prop where needed.
  try {
    data = doExtractData ? json.data : json;
  } catch {
    log({
      level: LOG_TYPE.error,
      message: 'JSON dont have data property',
      stringify: { requestUrl, json },
    })
  }

  // SWR keys/url needs to be identical for both client and server so as to SSR fallback works properly.
  // Provide SWR key on the server so the server data can be passed to the client.
  // null for sake of serializing.
  const swrKey = getClientsideUrl(requestUrl);
  const result = process.browser ? data : [data, swrKey, fetchDuration];

  return result;
};

const getJson = async (requestUrl, headers) => {
  let response;

  const requestOptions = {
    method: 'GET',
    headers,
    redirect: 'follow',
    referrer: 'no-referrer',
    cache: 'no-cache',
  };

  const fetchOptions =
    process.env.NODE_ENV === 'development' ? { agent: getAgentObject() } : {};

  const request = new Request(requestUrl, { ...requestOptions });

  await log({
    level: LOG_TYPE.debug,
    message: 'Fetch setup',
    stringify: {
      requestUrl,
      requestOptions,
      props: {
        headers: headers && Object.fromEntries(headers?.entries?.() || []),
        requestUrl,
        requestOptions,
      }
    },
  })

  try {
    response = await fetch(request, fetchOptions);

    if (!response.ok) {
      log({
        level: LOG_TYPE.error,
        message: `Fetch response error ${response.status}`,
        stringify: {
          status: response.status,
          requestUrl, props: {
            response: debugFetchResponse(response),
            headers: headers && Object.fromEntries(headers?.entries?.() || []),
            requestUrl,
            requestOptions,
          }
        },
      })
    }
  } catch (error) {
    log({
      level: LOG_TYPE.error,
      message: 'Fetch error (from catch)',
      stringify: {
        requestUrl,
        error,
        props: {
          headers: headers && Object.fromEntries(headers?.entries?.() || []),
          requestUrl,
          requestOptions,
        }
      },
    })
  }

    // throw new Error(
    //   `An error occurred while fetching the data.\n\nHint: Check whether the BE is accessible (is BE running? is Nginx running? do you use proper network?).\n\nHTTP response: ${response.status
    //   } ${response.statusText
    //   }\n\nURL: ${requestUrl}\n\nrequest options: ${JSON.stringify(
    //     requestOptions,
    //   )}\n\nheaders: ${JSON.stringify(
    //     Object.fromEntries(headers.entries()),
    //   )}\n\nrequest: ${JSON.stringify(request)}\n\nhttp agent: ${JSON.stringify(
    //     !!fetchOptions?.agent,
    //   )}\n\nresponse content:` + JSON.stringify(response.text()),
  // );

  try {
    const json = await response.json();
    log({
      level: LOG_TYPE.debug,
      message: 'fetched json',
      stringify: { requestUrl, json },
    });

    return json;
  } catch (error) {
    // throw new Error(
    //   `JSON is not valid.\n\nHint: Check BE output.\n\nURL: ${requestUrl}\n\n${JSON.stringify(
    //     error,
    //   )}`,
    // );
    log({
      level: LOG_TYPE.error,
      message: 'JSON is not valid',
      stringify: {
        requestUrl, error, props: {
          response: debugFetchResponse(response),
          headers: headers && Object.fromEntries(headers?.entries?.() || []),
          requestUrl,
          requestOptions,
        }
      },
    })
  }
};

export default async function fetcher(
  requestUrl,
  doExtractData = true,
  cookie,
  referer,
  jwt,
) {
  // Handle unallowed characters in params.
  if (!checkForUnallowedCharacters(requestUrl))
    return process.browser
      ? null
      : [doExtractData ? null : { data: null }, null];

  const headers = prepareHeaders(requestUrl, cookie, referer, jwt);

  const startTime = Date.now();
  const json = await getJson(requestUrl, headers);
  const msElapsed = Date.now() - startTime;
  const fetchDuration = msElapsed / 1000;

  const result = prepareData(json, doExtractData, requestUrl, fetchDuration);

  return result;
}
