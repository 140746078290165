import styled from 'styled-components';

export default styled.ul`
  padding: 0 2em 0 0;
  display: flex;
  height: 100%;
  width: calc((100% - 250px) / 2);
  min-width: 366px;
  word-break: keep-all;
  @media screen and (max-width: 64em) {
    display: none;
  }
`;
