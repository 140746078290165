import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 1em;
  font-size: 0.5rem;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  text-transform: uppercase;
  font-weight: bold;
`;

function ImgPlaceholder() {
  return <Wrapper>No image set</Wrapper>;
}

export default ImgPlaceholder;
