/**
 * @file Fetching REST API on both server nad client.
 * @description for usage use default export eg.: const [ data, swrKey ] = await fetch.articles.forSSR({language}).
 * This will combine the preset defined in endpointPresets with prop object.
 * And than construct the URL based on the endpoint and query param.
 * @todo middlewares (categoriesBySlug, getCategoryData, guidesData, dataByType, guidesData, dataByTag etc.)
 */
import { useContext, useMemo, useState, useEffect } from 'react';

import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import Proxy from 'es6-proxy-polyfill';
import Head from 'next/head';
import { isEqual } from 'lodash';

import fetcher from './fetcher';
import { prepareUrl } from '../../app/utils/helpers';
import { GlobalContext } from '../../app/components/GlobalProvider';
import { endpointPresets } from './presets';
import checkBackendCache from '../../pages/api/backendCache';
import { log, LOG_TYPE } from '../../app/utils/logger';

/**
 * @description SWR keys/url needs to be identical for both client and server so as to SSR fallback works properly.
 */
export const getClientsideUrl = url => {
  return url.replace(
    process.env.WP_API_SERVER,
    process.browser
      ? `${window.location.origin}/`
      : `${process.env.NEXT_PUBLIC_ORIGIN}/`,
  );
};

/**
 * @description ammending of URL/SWR key before fetch in order to apply client side state.
 */
const useQuery = ({ endpoint = null, fallbackKey = null, query = null }) => {
  let params = {};

  const { password } = useContext(GlobalContext);

  if (
    password &&
    process.browser &&
    window.sessionStorage
      .getItem('pkb_password_protected_slug')
      ?.replace(/^\D+/g, '')
  ) {
    params.password = password;
  }
  // if (isPreview || roles.includes('employee')) {
  //   console.count('###isPreview');
  //   params.preview = isPreview;
  //   params.forced = true;
  //   params.admin = true;

  //   if (process.browser && sessionStorage.getItem('jwt_token')) {
  //     params.jwt = true;
  //   }
  // }

  const url = prepareUrl({
    baseUrl: fallbackKey || endpoint,
    query: fallbackKey ? params : { ...query, ...params },
  });

  return url;
};

const useFallback = (fallback, endpoint) => {
  if (!fallback) return [null, null];

  const pickFallback = Object.entries(fallback).filter(([key, value]) =>
    key.includes(endpoint),
  )[0];

  return [pickFallback?.[0], pickFallback?.[1]];
};

/**
 * @description prefetch two more pages of a infinite scroll.
 * @todo prefetcPages
 */
export const Prefetch = ({ swrKey }) => {
  let prefetchPages = process.env.NEXT_PUBLIC_PREFETCH_INFINITE_SCROLL_PAGES;

  const incrementPage = (swrKey, index) => {
    try {
      const url = new URL(swrKey);
      url.searchParams.set('page', index + 2);
      return url.toString();
    } catch (error) {
      log({
        level: LOG_TYPE.info,
        message: 'incrementPage error',
        stringify: { error, swrKey, index },
      });
    }
  }

  return (
    <Head>
      {new Array(prefetchPages).fill(null).map((value, index) => (
        <link
          rel="preload"
          href={incrementPage(swrKey, index)}
          as="fetch"
          key={`prefetch${index}`}
        />
      ))}
    </Head>
  );
};

/**
 * @description SWR fallback key used for infinite fetch must be defined before other keys of the same endpoint for now.
 * Fallback key is such case is matched only by endpoint URL not the whole SWR key.
 */
export const useInfiniteSwr = ({ endpoint, query, fallback }) => {
  const [fallbackKey, fallbackData] = useFallback(fallback, endpoint);
  const fallbackUrl = useQuery({ endpoint, query, fallbackKey });

  const SwrKeyGen = pageIndex => {
    const url = new URL(fallbackUrl);
    url.searchParams.set('page', pageIndex + 1);
    const swrKey = url.toString();

    return swrKey;
  };

  const { data, isValidating, size, setSize, error } = useSWRInfinite(
    SwrKeyGen,
    fetcher,
    {
      fallback: { [fallbackKey]: fallbackData },
      fallbackData,
    },
  );

  return {
    data,
    isLoading: process.browser && (data && size > 1) && isValidating,
    fallbackKey: fallbackUrl,
    isError: error,
    size,
    setSize,
  };
};

/**
 * @description for fallback you can either use solely the fallback object or again specify indidual params.
 * @param {canFetch} function conditional fetching based on the query object.
 * @param {fallback} object data for hydratation.
 */
const useSwrApi = ({
  canFetch: can,
  endpoint,
  query,
  middleware,
  fallback,
  options = {},
  extractDataProp,
  cookie,
  referer,
}) => {
  let modyfiedData = null;
  const [fallbackKey, fallbackData] = useFallback(fallback, endpoint);
  const url = useQuery({ endpoint, query, fallbackKey });
  let canFetch = useMemo(() => {
    if (typeof can === 'function') return can(query) || false;
    else return url === fallbackKey ? false : true;
  }, [query]);
  const [getOutput, setOutput] = useState({
    data:
      typeof middleware === 'function' && fallbackData
        ? middleware(fallbackData)
        : fallbackData || [],
  });

  if (url === fallbackKey && fallbackData) options.fallbackData = fallbackData;
  if (fallbackKey && fallbackData)
    options.fallback = { [fallbackKey]: fallbackData };

  const { data: fetchedData, error, isValidating, mutate } = useSWR(
    canFetch ? url : null,
    key => fetcher(key, extractDataProp, cookie, referer),
    {
      ...options,
    },
  );

  // Apply middleware processing fetched data.
  if (typeof middleware === 'function' && fetchedData) {
    modyfiedData = middleware(fetchedData);
  }

  const data = modyfiedData || fetchedData || null;

  useEffect(() => {
    setOutput({
      data,
      isLoading: isValidating && !error && !fetchedData,
      isValidating,
      isError: error,
      swrKey: url,
      refetch: () => mutate(),
    });
  }, [url, isEqual(getOutput.data, data)]); // ###

  return getOutput;
};

async function fetchApi({
  endpoint,
  query,
  extractDataProp,
  cookie,
  jwt,
  referer,
  medical = false,
}) {
  const url = prepareUrl({ baseUrl: endpoint, query });

  if (!process.browser && !query?.admin) {
    const keyDbCache = await checkBackendCache(url, extractDataProp, medical);
    if (keyDbCache?.[0]) {
      log({
        level: LOG_TYPE.cache,
        message: 'KeyDB match',
        stringify: {
          url: url.replace(
            `${process.env.WP_API_SERVER}`,
            `${process.env.NEXT_PUBLIC_ORIGIN}/`,
          ),
          props: { endpoint, query, extractDataProp, cookie: !!cookie, referer, jwt: !!jwt, medical },
          cache: {
            key: keyDbCache?.[1],
            dataLength: keyDbCache?.[0]?.length || null,
            dataKeys: keyDbCache?.[0]?.map?.(item => item?.[0]),
             WordPressUrl: keyDbCache?.[2],
            cacheKey: keyDbCache?.[3],
            medical: medical,
          }
        },
      });

      return keyDbCache;
    }
  }

  const fetchedData = await fetcher(url, extractDataProp, cookie, referer, jwt);
  log({
    level: LOG_TYPE.info,
    message: 'fetching data',
    stringify: {
      time: `${fetchedData?.[2]}s`,
      medical,
      browser: process.browser,
      props: { endpoint, query, extractDataProp, cookie: !!cookie, referer, jwt: !!jwt },
      url: url.replace(
        `${process.env.WP_API_SERVER}`,
        `${process.env.NEXT_PUBLIC_ORIGIN}/`,
      ),
      data: {
        length: fetchedData?.[0]?.length || null,
      }
    },
  })

  return fetchedData;
}

const getMergedProps = (props, property) => {
  // Get specific preset and set language.
  const preset = endpointPresets(props?.language || '')[property];
  // Merge and rewrite props.
  const mergedProps = {
    ...preset,
    ...props,
    query: { ...preset.query, ...(props?.query || {}) },
  };

  if (props?.medical) {
    mergedProps.query.medical = props?.medical;
  }

  return mergedProps;
};

const handler = {
  get: function (endpointPresets, property) {
    return {
      withSWR: props => {
        const mergedProps = getMergedProps(props, property);
        mergedProps.endpoint = getClientsideUrl(mergedProps.endpoint);
        return props?.options?.infinite
          ? useInfiniteSwr(mergedProps)
          : useSwrApi(mergedProps);
      },
      forSSR: props => {
        const mergedProps = getMergedProps(props, property);
        return fetchApi(mergedProps);
      },
    };
  },
};

const fetch = new Proxy(endpointPresets, handler);

export const fetchClosureForSSR = ({ predefinedProps, predefinedQuery }) => {
  return ({ subject, extractDataProp, query }) => {
    const fetchProps = {
      ...predefinedProps,
      query: {
        ...predefinedQuery,
        ...query,
      },
    };

    if (extractDataProp !== undefined) {
      fetchProps.extractDataProp = extractDataProp;
    }

    return fetch[subject].forSSR(fetchProps);
  };
};

/**
 * @description Proxy is used for sake of simplifying interface of fetching SSR data
 * for later use with SWR. It allows to use complex objects and still keep consintency
 * between server and client (SWR key for fallback).
 * @example usage server side: const [ data, key ] = await fetch.articles.forSSR({ language, query { ... } })
 * @example usage client side: const { data, isLoading } = fetch.articles.withSWR({ fallback: swrFallback })
 */
export default fetch;
