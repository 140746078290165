import Redis from 'ioredis';
import md5 from 'md5';

import { getClientsideUrl } from '../../data/restApi/fetchApi';

const createRedisInstance = () => {
  if (process.browser) return null;

  const redisInstance = new Redis(process.env.KEYDB_HOST, process.env.KEYDB_PORT);

  redisInstance.on('error', function (error) {
    if (process.env.NEXT_PUBLIC_DEBUG_MODE === 'true' || !process.env.browser)
      console.error('Redis error: ', error);
  });

  return redisInstance;
}

const redis = createRedisInstance();

const getWordpressishUrl = (urlString, medical) => {
  const url = new URL(urlString);
  const match = url.pathname.match(/\/api\/v1\/(.*)/);

  if (!match?.[1]) return null;

  const WordPressUrl = `${match?.[1]}?q=${url.pathname}&${url.search.replace(
    '?',
    '',
  )}`;

  const numberSlug = WordPressUrl.replace(
    /(slug|category|parent)=.+_(\d+)/,
    '$1=$2',
  );

  const slugToId = numberSlug.replace(/(slug)=(\d+)/, 'id=$2');

  const wordpressishUrl = slugToId; // medical ? `medical${slugToId}` : slugToId;

  return wordpressishUrl;
};

const fetchCache = cacheKey => {
  return new Promise((resolve, reject) => {
    redis.get(cacheKey, function(error, data) {
      if (error) reject(error);
      if (data) {
        const clientData = JSON.parse(data);
        resolve(clientData);
      }
      resolve(null);
    });
  });
};

const checkCache = async (requestUrl, extractDataProp = true, medical = false) => {
  if (process.browser) return null;

  const swrKey = getClientsideUrl(requestUrl);
  const WordPressUrl = getWordpressishUrl(requestUrl, medical);

  if (!WordPressUrl) return null;
  
  const cacheKey = md5(WordPressUrl);
  const cacheResponse = await fetchCache(cacheKey);

  if (!cacheResponse) return null;

  const clientData = extractDataProp ? cacheResponse.data : cacheResponse;

  if (process.env.NEXT_PUBLIC_DEBUG_MODE === 'true')
    console.log(
      '\x1b[32m%s\x1b[0m',
      'fetched json',
      '\x1b[1m',
      requestUrl,
      clientData,
    );

  return [clientData, swrKey, WordPressUrl, cacheKey];
};

export default checkCache;
