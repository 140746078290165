/**
 * @file ItemType of search results.
 */
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNewspaper,
  faWrench,
  faTools,
  faBook,
  faServer,
  faPrint,
} from '@fortawesome/pro-solid-svg-icons';

import messages from './messages'
import { usePruslation } from '../../utils/hooks';


function ItemType({ type }) {
  const { t } = usePruslation('common');

  return t(messages[type]);
}

ItemType.propTypes = {
  type: PropTypes.string.isRequired,
};

const iconMap = {
  manuals: faTools,
  guides: faWrench,
  helps: faNewspaper,
  tooltips: faBook,
  category: faServer,
  post_tag: faPrint,
};

export function ItemTypeIcon({ type }) {
  return <FontAwesomeIcon icon={iconMap[type]} />;
}

ItemTypeIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ItemType;
