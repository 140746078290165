/**
 * @file SEO HTML headers
 */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import htmlEntities from 'he';
import { useRouter } from 'next/router';

import { usePruslation } from '../../utils/hooks';
import { SHOULD_USE_CDN, CDN_URL } from '../../utils/constants'
import messages from './messages';

const textWithEllipsis = ({ text, maxLength }) => {
  if (typeof text !== 'string') return null;
  let ellipsifiedString = '';

  const regexp = new RegExp(`^(.{0,${maxLength - 1}}[a-z])[\\W]+`, 'i');
  const match = text.match(regexp);

  if (text.length > maxLength)
    ellipsifiedString = match && match.length > 1 ? `${match[1]}…` : text;
  else
    ellipsifiedString = text;

  return ellipsifiedString;
};

const getPureText = string => {
  var stripedHtml = string.replace(/<[^>]+>/g, '');
  var pureString = htmlEntities.decode(stripedHtml);

  return pureString;
};

const getProperTitles = title => {
  const siteBrand = 'Prusa Knowledge Base';

  const baseTitle = `${title ? `${title} | ` : ''}${siteBrand}`;

  const twitterTitle = textWithEllipsis({
    text: baseTitle,
    maxLength: 55,
  });

  return {
    base: baseTitle,
    twitter: twitterTitle,
  };
};

const getProperDescriptions = description => {
  const purifiedDescription = getPureText(description);

  const baseDescription = textWithEllipsis({
    text: purifiedDescription,
    maxLength: 140,
  });

  const ogDescription = textWithEllipsis({
    text: purifiedDescription,
    maxLength: 65,
  });

  return {
    base: baseDescription,
    og: ogDescription,
  };
};

function SEOHeader({ description, title = null, image = { original: '/img/Original_Prusa_3D_printers.png' }, keywords, translations }) {
  const router = useRouter();
  const { t } = usePruslation();
  const [getImageMeta, setImageMeta] = useState(null);

  const FONT_URL_PREFIX = SHOULD_USE_CDN ? CDN_URL : '';

  const titleMemo = useMemo(() => getProperTitles(title), [title]);

  const descriptionMemo = useMemo(
    () => getProperDescriptions(description || t(messages.description, { pageTitle: title })),
    [description],
  );

  const xDefaultHref = translations?.['en']?.slug
    ? `${process.env.NEXT_PUBLIC_ORIGIN}${router.asPath.replace(/\/[^\/]+?$/, `/${translations['en'].slug}`)}` 
    : `${process.env.NEXT_PUBLIC_ORIGIN}${router.asPath}`;

  useEffect(() => {
    if (image?.original) {
      const img = new Image();
      img.onload = () => {
        setImageMeta({ w: img.width, h: img.height });
      };
      img.src = image.original;
    }
  }, [image.original]);

  return (
    <Head>
      <title>{titleMemo.base}</title>

      {titleMemo.base ? <meta name="subject" content={titleMemo.base} /> : null}
      {descriptionMemo.base ? (
        <meta name="description" content={descriptionMemo.base} />
      ) : null}
      {keywords ? <meta name="keywords" content={keywords} /> : null}

      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_ORIGIN}${router.asPath}`}
      />
      {titleMemo.base ? (
        <meta property="og:title" content={titleMemo.base} />
      ) : null}
      {image?.original ? (
        <meta property="og:image" content={image.original} />
      ) : null}
      {getImageMeta ? (
        <meta property="og:image:width" content={getImageMeta.w} />
      ) : null}
      {getImageMeta ? (
        <meta property="og:image:height" content={getImageMeta.h} />
      ) : null}
      {descriptionMemo.og ? (
        <meta property="og:description" content={descriptionMemo.og} />
      ) : null}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Prusa3D" />
      {titleMemo.twitter ? (
        <meta name="twitter:title" content={titleMemo.twitter} />
      ) : null}
      {descriptionMemo.base ? (
        <meta name="twitter:description" content={descriptionMemo.base} />
      ) : null}
      {titleMemo.twitter ? (
        <meta name="twitter:image:alt" content={titleMemo.twitter} />
      ) : null}
      {image?.original ? (
        <meta name="twitter:image" content={image.original} />
      ) : null}

      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

      <link rel="preload" href={`${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Regular.ttf`} as="font" crossOrigin="anonymous" />
      <link rel="preload" href={`${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-RegularItalic.ttf`} as="font" crossOrigin="anonymous" />
      <link rel="preload" href={`${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Medium.ttf`} as="font" crossOrigin="anonymous" />
      <link rel="preload" href={`${FONT_URL_PREFIX}/visuals/fonts/AtlasGrotesk-Black.ttf`} as="font" crossOrigin="anonymous" />

      {<link
        rel="alternate"
        href={xDefaultHref}
        hrefLang='x-default'
        key='x-default'
      />}
      {router.locales.map(locale => {
        // Pages with dynamic path provide object with translations containin proper slug.
        const translation = translations?.[locale];

        if (!translation && locale === 'ja') return null; // Exclude Japanese in case of pages with static path.
        if (translation && translation?.translated != 1) return null; // Exclude not translated language mutations for dynamic paths.

        const localePath = locale === 'en' ? '' : `/${locale}`;
        const pagePath = (translation) ? router.asPath.replace(/\/[^\/]+?$/, `/${translation.slug}`) : router.asPath;

        return (
          <link
            rel="alternate"
            href={`${process.env.NEXT_PUBLIC_ORIGIN}${localePath}${pagePath}`}
            hrefLang={locale}
            key={`${locale}-${pagePath}`}
          />
        );
      })}

      <script src="/js/tinymce/tinymce.min.js" referrerpolicy="origin"></script>
    </Head>
  );
}

SEOHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  keywords: PropTypes.string,
};

export default SEOHeader;
