import styled from 'styled-components';

export const Grid = styled.div<{
  noMargin?: boolean;
  align?: string;
  breakpoint?: number;
  mobileReverse?: boolean;
  mobileHidden?: boolean;
  visible?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  ${props => (props.noMargin ? '' : 'margin: 0 -1em;')}
  transition: max-height 300ms;
  justify-content: ${props => props.align || 'flex-start'};
  @media screen and (max-width: ${props => props.breakpoint || 64}em) {
    margin: 0;
    overflow: hidden;
    ${props => (props.mobileReverse ? 'flex-direction: column-reverse;' : '')}
    max-height: ${props =>
    props.mobileHidden ? (!props.visible ? '0' : '9999px') : 'none'};
  }
`;

export const Col = styled.div<{
  cols?: number;
  padding?: boolean;
  noPadding?: boolean;
  breakpoint?: number;
}>`
  position: relative;
  overflow: hidden;
  width: calc(${props => 100 / (12 / (props.cols || 12))}% - 0.7em);
  transition: width 400ms;
  margin: 0.5em;
  ${props => props.padding && 'padding: 0 3rem;'}
  @media screen and (min-width: 64em) {
    &:first-child {
      ${props => !props.noPadding && `padding-left: 1em;`}
      margin-left: 0;
    }
    &:last-child {
      ${props => !props.noPadding && `padding-right: 1em;`}
      margin-right: 0;
    }
  }
  @media screen and (max-width: 64em) {
    width: calc(${props => 200 / (12 / (props.cols || 12))}% - 1em);
    max-width: 100%;
    ${props => !props.noPadding && `padding: 0 0.5rem;`}
  }
  @media screen and (max-width: ${props => props.breakpoint || 48}em) {
    width: 100% !important;
  }
`;

export default Grid;
