/**
 * @file App component
 * @todo Google Tag Manager
 * @todo ScrollToTop ###
 */
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import appWithI18n from 'next-translate/appWithI18n';
import { SWRConfig } from 'swr';
import NextNprogress from 'nextjs-progressbar';
import TagManager from 'react-gtm-module';
import { config } from '@fortawesome/fontawesome-svg-core';
import Script from 'next/script';
import { useRouter } from 'next/router';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import i18nConfig from '../../i18n';
import Layout from './_appLayout';

// CSS files
import 'sanitize.css/sanitize.css';

// ### duplicite themes
const theme = {
  colors: {
    main: '#e05d2d',
    smoggishGrey: 'rgb(245, 245, 245)',
    lightGrey: '#e0e0e0',
    grey: '#B3B3B3',
    text: '#000000',
  },
  breakpoints: {
    mobile: '576px',
  },
};

let authRedirectLocalstorage = undefined;
try {
  authRedirectLocalstorage =
    process.browser && window.localStorage?.getItem('pkb_redirect_url');
  if (authRedirectLocalstorage) {
    window.localStorage?.removeItem('pkb_redirect_url');
    const redirectUrl = JSON.parse(authRedirectLocalstorage);
    window.location.replace(redirectUrl);
  }
} catch (e) {
  // local storage is not available
}

function App({ Component, pageProps }) {
  const { locale, push, asPath } = useRouter();

  useEffect(() => {

    //switch locale is previously selected 
    if (asPath === '/' && localStorage.getItem('prusuki_language_choice') && localStorage.getItem('prusuki_language_choice') !== locale) {
      push(asPath, asPath, { locale: localStorage.getItem('prusuki_language_choice') });
    }

    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
      events: {
        search: 'Search',
        feedback: 'Feedback',
      },
    });
  }, []);

  if (authRedirectLocalstorage) return null;

  return (
    <SWRConfig value={{ revalidateOnFocus: false }}>
      <Script
        id="stripe-js"
        src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
        data-domain-script={`24d209b9-25f4-4196-8d7a-b306313b85d5${
          process.env.TESTING_BUILD === 'true' ? '-test' : ''
        }
         `}
        strategy="lazyOnload"
      />
      <Script
        id="livechat"
        dangerouslySetInnerHTML={{
          __html: `
          window.__lc = window.__lc || {};
          window.__lc.license = 9644675;
          ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
        `,
        }}
        strategy="afterInteractive"
      />
      <ThemeProvider theme={theme}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
          language={locale}
          useRecaptchaNet={true}
          useEnterprise={true}
          scriptProps={{
            async: true,
            defer: true,
            appendTo: 'body',
          }}
        >
          <Layout>
            <NextNprogress
              color="#fa6831"
              startPosition={0.3}
              stopDelayMs={200}
              height={2}
              showOnShallow
              options={{ showSpinner: false }}
            />
            <Component {...pageProps} />
          </Layout>
        </GoogleReCaptchaProvider>
      </ThemeProvider>
    </SWRConfig>
  );
}

// Needed for sake of the custom components in structure above pages.
// Might be solved in future versions of next-translate.
export default appWithI18n(App, {
  ...i18nConfig,
  skipInitialProps: true,
});
