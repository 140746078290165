/**
 * @file search content
 */
import React, { useState, useCallback } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';
import Link from 'next/link';

import messages from './messages';
import fetch from '../../../data/restApi/fetchApi';
import { usePruslation, useDebounce } from '../../utils/hooks';
import ItemType from '../../components/ItemType';
import Loader from '../../components/Loader';
import { Wrapper, ButtonBig, Grid, Col } from '../../components/layout';
import IntlText from '../../components/IntlText';

import Suggestion from './Suggestion';
import Input, { Icon, InputContainer } from './Input';
import Menu, { MenuItem, Section } from './Menu';

const MIN_NUMBER_OF_CHARACTERS = 2;

const Test = styled.div``;

const Main = styled.div`
  width: auto;
  display: inline-block;
  max-width: 100%;
  @media print {
    display: none;
  }
`;

function preventResetOnBlur(state, changes) {
  if (changes.type === Downshift.stateChangeTypes.blurInput) {
    return {}; // no-changes
  }
  return changes;
}

function SearchInput({
  borderColor = 'rgb(250, 104, 49)',
  height = 100,
  typesToRender = ['post_tag', 'category', 'manuals', 'guides', 'helps'],
  inAdmin = false,
  defaultValue = '',
  disableAutocomplete = false,
  global,
}) {
  const router = useRouter();
  const [value, setValue] = useState(defaultValue);
  const { t, lang: language } = usePruslation();
  const [handleChangeDebounced] = useDebounce(handleChange, 500);
  const [handleGtmEventDebounced] = useDebounce(handleGtmEvent, 1000);

  const { data: suggested, isLoading } = fetch.search.withSWR({
    language,
    query: {
      search: value,
      category_chain: true,
    },
    canFetch: query => query.search?.length >= MIN_NUMBER_OF_CHARACTERS,
    middleware: data => {
      return (
        data &&
        data.reduce((acc, val) => {
          if (!acc[val.type]) {
            acc[val.type] = [];
          }
          acc[val.type].push(val);
          return acc;
        }, {})
      );
    },
  });

  async function handleGtmEvent(searchPhrase) {
    TagManager.dataLayer({
      dataLayer: {
        event: 'search',
        searchPhrase: searchPhrase.toLowerCase(),
      },
    });
  }

  const getSearchUrl = val => {
    let basepath = '/search';

    if (global?.data?.isMedical) {
      basepath = '/search-ep';
    }

    if (router.pathname === '/search-support') {
      basepath = '/search-support';
    }

    return `${basepath}?s=${encodeURIComponent(val)}`;
  };

  const pushSearchRoute = useCallback(inputValue => {
    let basepath = '/search';

    if (global?.data?.isMedical) {
      basepath = '/search-ep';
    }

    if (router.pathname === '/search-support') {
      basepath = '/search-support';
    }

    return router.push({
      pathname: basepath,
      query: { s: inputValue },
    });
  }, []);

  async function handleChange(
    { inputValue, selectedItem, isOpen = null },
    helpers,
  ) {
    // Abort when selecting item?
    if (selectedItem && inputValue === selectedItem?.title) return;
    if (inputValue && inputValue.length >= MIN_NUMBER_OF_CHARACTERS) {
      // handleGtmEventDebounced(inputValue);
      setValue(inputValue);
      router.prefetch(getSearchUrl(inputValue));
    } else if (
      !inputValue ||
      (inputValue && inputValue.length < MIN_NUMBER_OF_CHARACTERS)
    ) {
      helpers.closeMenu();
      setValue(inputValue);
    }
  }

  function handleSelected(item, helpers) {
    helpers.closeMenu();
  }

  if (disableAutocomplete) {
    return (
      <Main>
        <form autoComplete="off">
          <InputContainer>
            <Icon>
              <FontAwesomeIcon icon={faSearch} />
            </Icon>
            <label htmlFor="search-input" style={{ display: 'none' }}>
              Search
            </label>
            <Input
              id="search-input"
              autoComplete="off"
              role="presentation"
              {...{
                borderColor,
                placeholder: t(messages.main),
                value,
                onChange: e => setValue(e.target.value),
                onKeyDown(e) {
                  if (e.key === 'Enter' && highlightedIndex === null) {
                    e.preventDefault();
                    pushSearchRoute(inputValue);
                  }
                  return null;
                },
              }}
              tabindex={1}
            />
          </InputContainer>
        </form>
      </Main>
    );
  }

  return (
    <Main>
      <Downshift
        onChange={handleSelected}
        onStateChange={handleChangeDebounced}
        itemToString={i => (i ? i?.title : '')}
        initialInputValue={defaultValue}
        stateReducer={preventResetOnBlur}
      >
        {({
          getInputProps,
          getRootProps,
          getLabelProps,
          getItemProps,
          closeMenu,
          isOpen,
          inputValue,
          highlightedIndex,
        }) => (
          <Test {...getRootProps()}>
            <InputContainer>
              <Icon>
                <FontAwesomeIcon icon={faSearch} size="lg" />
              </Icon>
              <label
                id="search-input"
                {...getLabelProps()}
                style={{ display: 'none' }}
              >
                Search
              </label>
              <form autoComplete="off">
                <Input
                  id="search-input"
                  autoComplete="off"
                  role="presentation"
                  {...getInputProps({
                    borderColor,
                    placeholder: t(messages.main),
                    onKeyDown(e) {
                      if (e.key === 'Enter' && highlightedIndex === null) {
                        e.preventDefault();
                        closeMenu();
                        pushSearchRoute(inputValue);
                      }
                      return null;
                    },
                  })}
                  tabindex={1}
                />
              </form>
            </InputContainer>
            {isOpen && inputValue.length >= MIN_NUMBER_OF_CHARACTERS ? (
              <Menu maxHeight={height}>
                {isLoading ? (
                  <Loader fullPage />
                ) : (
                  <Wrapper>
                    <Grid noMargin>
                      <Col
                        cols={
                          suggested?.tooltips && suggested.tooltips.length > 0
                            ? 8
                            : 12
                        }
                      >
                        {suggested && Object.keys(suggested).length > 0 ? (
                          typesToRender.map(
                            cat =>
                              suggested[cat] && (
                                <Section key={cat}>
                                  <h4>
                                    <ItemType type={cat} />
                                  </h4>
                                  {suggested[cat].map((suggestion, index) => (
                                    <Suggestion
                                      key={`${inputValue}${index}`}
                                      {...{
                                        language,
                                        inAdmin,
                                        suggestion,
                                        index,
                                        itemProps: getItemProps({
                                          item: suggestion,
                                        }),
                                        highlightedIndex,
                                        inputValue,
                                        column: 'title',
                                      }}
                                    />
                                  ))}
                                </Section>
                              ),
                          )
                        ) : (
                          <>
                            {value?.length >= MIN_NUMBER_OF_CHARACTERS && (
                              <Link href="/" passHref>
                                <MenuItem
                                  key="404"
                                  style={{ textAlign: 'center' }}
                                >
                                  <IntlText index={messages.nothingFound} />
                                </MenuItem>
                              </Link>
                            )}
                          </>
                        )}
                      </Col>
                      {suggested?.tooltips && suggested.tooltips.length > 0 && (
                        <Col cols={4}>
                          <Section>
                            <h4>
                              <IntlText index={messages.glossary} />
                            </h4>
                            {suggested.tooltips.map((suggestion, index) => (
                              <Suggestion
                                key={`${inputValue}${index}`}
                                {...{
                                  language,
                                  inAdmin,
                                  suggestion,
                                  index,
                                  itemProps: getItemProps({ item: suggestion }),
                                  highlightedIndex,
                                  inputValue,
                                  column: 'title',
                                }}
                              />
                            ))}
                          </Section>
                        </Col>
                      )}
                    </Grid>
                    {suggested && Object.keys(suggested).length > 0 && (
                      <div style={{ textAlign: 'center', marginTop: '2em' }}>
                        <Link href={getSearchUrl(inputValue)} passHref>
                          <ButtonBig>
                            <IntlText index={messages.showAllResults} />
                          </ButtonBig>
                        </Link>
                      </div>
                    )}
                  </Wrapper>
                )}
              </Menu>
            ) : null}
          </Test>
        )}
      </Downshift>
    </Main>
  );
}

SearchInput.propTypes = {
  borderColor: PropTypes.string,
  defaultValue: PropTypes.string,
  disableAutocomplete: PropTypes.bool,
  height: PropTypes.number,
  typesToRender: PropTypes.arrayOf(PropTypes.string),
  inAdmin: PropTypes.bool,
};

export default SearchInput;
