const CS_FLAG = '/img/icons/cs.png';
const DE_FLAG = '/img/icons/de.png';
const EN_FLAG = '/img/icons/en.png';
const ES_FLAG = '/img/icons/es.png';
const FR_FLAG = '/img/icons/fr.png';
const IT_FLAG = '/img/icons/it.png';
const JA_FLAG = '/img/icons/ja.png';
const PL_FLAG = '/img/icons/pl.png';

const iconMap = {
  en: EN_FLAG,
  es: ES_FLAG,
  cs: CS_FLAG,
  it: IT_FLAG,
  ja: JA_FLAG,
  de: DE_FLAG,
  fr: FR_FLAG,
  pl: PL_FLAG,
};

export const langMap = {
  en: 'English',
  cs: 'Čeština',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Polski',
  ja: '日本語',
};

export default iconMap;
