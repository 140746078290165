import styled from 'styled-components';

export default styled.div`
  background-color: white;
  ${props =>
    props.borderBottom &&
    `border-bottom: 1px solid ${props.theme.colors.lightGrey};`}
  ${props =>
    props.borderTop &&
    `border-top: 1px solid ${
      props.theme.colors.lightGrey
    };`}
 padding-bottom: 1em;
`;
