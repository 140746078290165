import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ErrorBoundary from '../app/components/ErrorBoundary';
import GlobalStyle from '../app/globalStyle';

const ContentWrapper = styled.div`
  margin-top: 40px;
  position: relative;
  z-index: 1;
  min-height: calc(100vh - 48px);
  max-width: 100vw;
`;

function Layout({ children }) {
  return (
    <>
      <GlobalStyle />
      <ContentWrapper>
        <ErrorBoundary>{children}</ErrorBoundary>
      </ContentWrapper>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Layout;
